import ContactForm from "../CompanyAndContact/ContactForm";
import NaverMapDefault from "../../common/NaverMaps/NaverMapDefault";
import { useTranslation } from "react-i18next";
import useHashScroll from "../../hooks/useHashScroll";

function ContactContent() {
  const { t } = useTranslation();
  useHashScroll();

  return (
    <div className="contact_wrapper">
      <div className="contact_inner">
        <div className="contact_box">
          <span className="contact_title" id="way">
            Way to Come
          </span>
          <span className="contact_subtitle">
            {t("ContactContents.RoadTitle")}
          </span>
          <div className="contact_grid_container">
            <div className="contact_grid">
              <div className="grid_item label_address">
                {t("ContactContents.Grid.HeadOfficeAddress")}
              </div>
              <div className="grid_item content traffic_content">
                {t("ContactContents.Grid.addressContent1")}
              </div>
              <div className="grid_item label_address">
                {t("ContactContents.Grid.R8DAddress")}
              </div>
              <div className="grid_item content traffic_content">
                {t("ContactContents.Grid.addressContent2")}
              </div>
              <div className="grid_item label">
                {t("ContactContents.Grid.contactPhone")}
              </div>
              <div className="grid_item content">
                {t("ContactContents.Grid.contactPhoneContent")}
              </div>

              <div className="grid_item label">
                {t("ContactContents.Grid.contactEmail")}
              </div>
              <div className="grid_item content">
                {t("ContactContents.Grid.contactEmailContent")}
              </div>

              <div className="grid_item label">
                {t("ContactContents.Grid.contactTraffic")}
              </div>
              <div className="grid_item content traffic_content">
                {t("ContactContents.Grid.contactTrafficContentFirst")}
                <br />
                {t("ContactContents.Grid.contactTrafficContentSecond")}
              </div>
            </div>
            <div className="contact_map">
              <NaverMapDefault />
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
}

export default ContactContent;
