// ContactForm.jsx
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomSelect from "./CustomSelect";
import useWindowWidth from "../../hooks/useWindowWidth";
import { irDownloadAlert } from "../../hooks/irDownloadAlert"; // 필요 시 import 추가

function ContactForm() {
  const { t } = useTranslation();
  const [mobile, setMobile] = useState(false);
  const viewWidth = useWindowWidth(0);
  const [formData, setFormData] = useState({
    inquiryType: "",
    email: "",
    emailId: "",
    emailDomain: "",
    customDomain: "",
    name: "",
    phone: "",
    contactTitle: "",
    message: "",
    agree1: false,
  });
  const [isCustomInput, setIsCustomInput] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  useEffect(() => {
    if (viewWidth <= 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [viewWidth]);

  const domainOptions = t("ContactContents.ContactUs.Form.domainSelectBox", {
    returnObjects: true,
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
      updatedData.email =
        updatedData.emailId +
        "@" +
        (updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : updatedData.emailDomain);
      updatedData.customDomain =
        updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : "";
      return updatedData;
    });
  };

  const handleEmailDomainChange = (value) => {
    if (value === "직접입력" || value === "Enter Email Domain") {
      setIsCustomInput(true);
      setFormData((prevData) => ({
        ...prevData,
        emailDomain: "", // 커스텀 입력을 위해 초기화
      }));
    } else {
      setIsCustomInput(false);
      setFormData((prevData) => ({
        ...prevData,
        emailDomain: value, // 선택된 도메인 설정
      }));
    }
  };

  const handleAgreeClick = (e) => {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      agree1: !prev.agree1,
    }));
  };

  const submitForm = async () => {
    // 유효성 검사 로직
    if (!formData.inquiryType) {
      alert(t("ContactContents.ContactUs.Form.Alert.inquiryType"));
      return;
    } else if (!formData.name) {
      alert(t("ContactContents.ContactUs.Form.Alert.name"));
      return;
    } else if (!formData.emailId) {
      alert(t("ContactContents.ContactUs.Form.Alert.emailId"));
      return;
    } else if (
      (formData.emailDomain === "직접입력" ||
        formData.emailDomain === "Enter Email Domain") &&
      !formData.customDomain
    ) {
      alert(t("ContactContents.ContactUs.Form.Alert.customDomain"));
      return;
    } else if (
      !formData.emailDomain &&
      !(
        formData.emailDomain === "직접입력" ||
        formData.emailDomain === "Enter Email Domain"
      )
    ) {
      alert(t("ContactContents.ContactUs.Form.Alert.emailDomain"));
      return;
    } else if (!formData.phone) {
      alert(t("ContactContents.ContactUs.Form.Alert.phone"));
      return;
    } else if (!formData.contactTitle) {
      alert(t("ContactContents.ContactUs.Form.Alert.contactTitle"));
      return;
    } else if (!formData.message) {
      alert(t("ContactContents.ContactUs.Form.Alert.Message"));
      return;
    } else if (!formData.agree1) {
      alert(t("ContactContents.ContactUs.Form.Alert.agree1"));
      return;
    }

    var today = new Date();

    var year = today.getFullYear();
    var month = ("0" + (today.getMonth() + 1)).slice(-2);
    var day = ("0" + today.getDate()).slice(-2);

    var dateString = year + "-" + month + "-" + day;
    var hours = ("0" + today.getHours()).slice(-2);
    var minutes = ("0" + today.getMinutes()).slice(-2);
    var seconds = ("0" + today.getSeconds()).slice(-2);
    var timeString = hours + ":" + minutes + ":" + seconds;

    const formDataToSubmit = {
      문의시간: dateString + " " + timeString,
      이메일: formData.email,
      이름: formData.name,
      휴대폰번호: formData.phone,
      문의분류: formData.inquiryType,
      문의제목: formData.contactTitle,
      문의내용: formData.message,
      개인정보수집동의: formData.agree1,
    };
    const dataToSubmit = new FormData();
    for (const key in formDataToSubmit) {
      dataToSubmit.append(key, formDataToSubmit[key]);
    }
    try {
      const response = await fetch(
        process.env.REACT_APP_CONTACT_APPSCRIPTS_URL,
        {
          method: "POST",
          body: dataToSubmit,
        }
      );
      if (response.ok) {
        irDownloadAlert(
          t("ContactContents.ContactUs.Form.Alert.SuccessResultMessage")
        );
      } else {
        irDownloadAlert(
          t("ContactContents.ContactUs.Form.Alert.FailResultMessage")
        );
      }
    } catch {
      irDownloadAlert(
        t("ContactContents.ContactUs.Form.Alert.FailResultMessage")
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  // 모달 닫기 함수
  const handleCloseModal = (e) => {
    if (e.target.className === "modal_overlay") {
      setShowPrivacyModal(false);
    }
  };

  return (
    <div className="contactform_form_wrapper">
      <div className="contactform_form_box">
        <form onSubmit={handleSubmit}>
          <div className="contactform_title_box">
            <span className="contactform_title" id="contactform">
              Contact Us
            </span>
            <span className="contactform_sub_title">
              {t("ContactContents.ContactUs.SubTitle")}
            </span>
          </div>

          {/* 문의 유형 선택 */}
          <div className="contactform_form_inner">
            <div className="contactform_label_box">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.contactType")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_input_box">
              <CustomSelect
                options={t(
                  "ContactContents.ContactUs.Form.contactTypeSelectBox",
                  {
                    returnObjects: true,
                  }
                )}
                onChange={(value) =>
                  setFormData({ ...formData, inquiryType: value })
                }
                required
              />
            </div>
          </div>

          {/* 이름 입력 */}
          <div className="contactform_form_inner">
            <div className="contactform_label_box">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.name")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_input_box contactform_input_box_border">
              <input
                type="text"
                name="name"
                onChange={handleChange}
                required
                placeholder={t(
                  "ContactContents.ContactUs.Form.placeholder.input"
                )}
                className="contactform_input"
              />
            </div>
          </div>

          {/* 이메일 입력 */}
          <div className="contactform_form_inner">
            <div className="contactform_label_box">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.email")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_email_input_box">
              <div className="email_input_container">
                <input
                  type="text"
                  name="emailId"
                  placeholder={t(
                    "ContactContents.ContactUs.Form.placeholder.input"
                  )}
                  onChange={handleChange}
                  required
                  className="contactform_email_input"
                />
                <span className="contactform_email_span">@</span>
                {mobile ? (
                  <CustomSelect
                    options={domainOptions}
                    onChange={handleEmailDomainChange}
                    required
                    allowCustomInput={true} // 모바일에서 커스텀 입력 허용
                  />
                ) : (
                  <div className="contactform_email_input_inner">
                    <input
                      type="text"
                      name="emailDomain"
                      placeholder={
                        isCustomInput
                          ? t(
                              "ContactContents.ContactUs.Form.placeholder.input"
                            )
                          : t(
                              "ContactContents.ContactUs.Form.placeholder.category"
                            )
                      }
                      value={formData.emailDomain}
                      disabled={!isCustomInput}
                      onChange={handleChange}
                      required
                      className="contactform_email_input_current"
                    />
                    <div className="contactform_custom_select_box">
                      <CustomSelect
                        options={domainOptions}
                        onChange={handleEmailDomainChange}
                        required
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* 휴대폰 번호 입력 */}
          <div className="contactform_form_inner">
            <div className="contactform_label_box">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.phone")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_input_box contactform_input_box_border">
              <input
                type="text"
                placeholder={t(
                  "ContactContents.ContactUs.Form.placeholder.phone"
                )}
                name="phone"
                onChange={handleChange}
                maxLength="12"
                minLength="9"
                required
                className="contactform_input"
              />
            </div>
          </div>

          {/* 문의 제목 입력 */}
          <div className="contactform_form_inner">
            <div className="contactform_label_box">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.contactTitle")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_input_box contactform_input_box_border">
              <input
                type="text"
                name="contactTitle"
                maxLength="20"
                placeholder={t(
                  "ContactContents.ContactUs.Form.placeholder.contactTitle"
                )}
                onChange={handleChange}
                required
                className="contactform_input"
              />
            </div>
          </div>

          {/* 문의 내용 입력 */}
          <div className="contactform_form_inner contactform_form_inner_textarea">
            <div className="contactform_label_box contactform_label_box_textarea">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.contactContents")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_text_area_inner">
              <textarea
                name="message"
                placeholder={t(
                  "ContactContents.ContactUs.Form.placeholder.contactContents"
                )}
                rows="5"
                onChange={handleChange}
                required
                maxLength={1000}
                className="contactform_text_area"
              />
              <span className="contactform_char_count">
                {formData.message.length}/1000
              </span>
            </div>
          </div>

          {/* 개인정보 수집 동의 */}
          <div className="irdownload_checkbox_wrapper">
            <div className="irdownload_checkbox_box">
              <input
                type="checkbox"
                name="agree1"
                onChange={handleChange}
                checked={formData.agree1}
                className="irdownload_checkbox_input"
                id="irdownload_agree1"
              />
              <div
                className={`irdownload_custom_checkbox ${
                  formData.agree1 ? "irdownload_checked" : ""
                }`}
                onClick={handleAgreeClick}
              ></div>
              <label
                htmlFor="irdownload_agree1"
                className="irdownload_checkbox_label"
                onClick={handleAgreeClick}
              >
                {t("CompanyContents.IRDownload.Form.privacyPolicy.Title")}
              </label>
              {mobile && (
                <button
                  type="button"
                  className="irdownload_view_details_button"
                  onClick={() => setShowPrivacyModal(true)}
                >
                  {t("agreeTranslation.modal.open")}
                </button>
              )}
            </div>
            {mobile && showPrivacyModal && (
              <div className="modal_overlay" onClick={handleCloseModal}>
                <div className="modal_content">
                  <div className="modal_irdownload_checkbox_box">
                    <input
                      type="checkbox"
                      name="agree1"
                      onChange={handleChange}
                      checked={formData.agree1}
                      className="modal_irdownload_checkbox_input"
                      id="irdownload_agree1"
                    />
                    <div
                      className={`modal_irdownload_custom_checkbox ${
                        formData.agree1 ? "modal_irdownload_checked" : ""
                      }`}
                      onClick={handleAgreeClick}
                    ></div>
                    <label
                      htmlFor="irdownload_agree1"
                      className="modal_irdownload_checkbox_label"
                      onClick={handleAgreeClick}
                    >
                      {t("CompanyContents.IRDownload.Form.privacyPolicy.Title")}
                    </label>
                    {mobile && (
                      <button
                        type="button"
                        className="modal_irdownload_view_details_button"
                        onClick={() => setShowPrivacyModal(false)}
                      >
                        {t("agreeTranslation.modal.close")}
                      </button>
                    )}
                  </div>
                  <p className="irdownload_privacy_policy_content irdownload_subtitle">
                    {t("agreeTranslation.modal.title")}
                  </p>
                  <p className="irdownload_privacy_policy_content irdownload_content_title">
                    {t("agreeTranslation.modal.subTitle")}
                  </p>
                  <div className="irdownload_privacy_policy_grid">
                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_item">
                      {t("agreeTranslation.modal.collections")}
                    </div>
                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_purpose">
                      {t("agreeTranslation.modal.purpose")}
                    </div>
                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_term">
                      {t("agreeTranslation.modal.period")}
                    </div>

                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_item_content">
                      {t("agreeTranslation.modal.email")}
                    </div>
                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_purpose_content">
                      {t("agreeTranslation.modal.inquiries")}
                    </div>
                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_term_content">
                      {t("agreeTranslation.modal.retain")}
                    </div>
                  </div>
                  <p className="irdownload_privacy_policy_content irdownload_warning">
                    {t("agreeTranslation.modal.information")}
                  </p>
                  <div className="irdownload_button_box">
                    <button type="submit" className="irdownload_submit_button">
                      {t("ContactContents.ContactUs.Form.Button")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {!mobile && (
              <>
                <p className="irdownload_privacy_policy_content irdownload_subtitle">
                  {t("agreeTranslation.modal.title")}
                </p>
                <p className="irdownload_privacy_policy_content irdownload_content_title">
                  {t("agreeTranslation.modal.subTitle")}
                </p>
                <div className="irdownload_privacy_policy_grid">
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_item">
                    {t("agreeTranslation.modal.collections")}
                  </div>
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_purpose">
                    {t("agreeTranslation.modal.purpose")}
                  </div>
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_term">
                    {t("agreeTranslation.modal.period")}
                  </div>
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_item_content">
                    {t("agreeTranslation.modal.email")}
                  </div>
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_purpose_content">
                    {t("agreeTranslation.modal.inquiries")}
                  </div>
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_term_content">
                    {t("agreeTranslation.modal.retain")}
                  </div>
                </div>
                <p className="irdownload_privacy_policy_content irdownload_warning">
                  {t("agreeTranslation.modal.information")}
                </p>
              </>
            )}
          </div>

          {/* 제출 버튼 */}
          <div className="contactform_button_box">
            <button
              type="submit"
              className="contactform_submit_button"
              onClick={handleSubmit}
            >
              {t("ContactContents.ContactUs.Form.Button")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
