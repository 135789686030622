import { useEffect, useState } from "react";
import useWindowWidth from "../../hooks/useWindowWidth";

function ContactBanner() {
  const [mobile, setMobile] = useState(false);
  const viewWidth = useWindowWidth(0);

  useEffect(() => {
    if (viewWidth <= 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [viewWidth]);

  return (
    <div className="banner_wrapper">
      <img
        src={
          mobile
            ? "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/a196c947-0f57-4150-f364-99b707793400/companybanner"
            : "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/1f7e226e-e836-490a-a224-6d739899a700/banner"
        }
        alt="Contact Banner"
        className={mobile ? "mobile_contact_banner_img" : "banner_img"}
        loading="lazy"
        decoding="async"
      />
      <div className="banner_content_box">
        <p className="nav_content">Home · Contact Us</p>
        <p className="banner_title">Contact Us</p>
      </div>
    </div>
  );
}

export default ContactBanner;
