import hanjogak from "../../../assets/images/메인_플랫폼_한조각.avif";
import wallet from "../../../assets/images/메인_플랫폼_월렛.avif";
import trade from "../../../assets/images/메인_플랫폼_트레이딩.avif";

export const solutionData = {
  hanjogak: {
    image: hanjogak,
    imagem:
      "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/380b02d6-4f86-469d-007f-49c3e3c63300/mobileplatform",
  },
  wallet: {
    image: wallet,
    imagem:
      "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/7ce1d2e4-a957-4681-096f-41f5db86ca00/mobileplatform",
  },
  trading: {
    image: trade,
    imagem:
      "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/8b3d7d85-6ade-4b55-c16d-49d1e8103400/mobileplatform",
  },
};

export const sliceList = [
  {
    id: "1",
    title: `(1) 재부상한 '토큰증권' 시장...추진 속도 불 붙을까`,
    summary: `시장 개막 대기하며 준비 서두르는 한국거래소·예탁결제원 하나증권, 토큰증권 발행·유통 준비 완료`,
    content: `
<p>[한스경제=박영선 기자] 토큰증권 법제화가 국회를 통과되면서 토큰증권 발행(STO) 시장 활성화에 대한 기대감이 높아졌다. 이에 토큰증권 시장을 향한 정치권의 지지가 확인되면서 관련사들의 움직임도 활발해지고 있다.</p></br>
<p>지난달 25일 정무위원회 소속 김재섭 국민의힘 의원이 'STO 제도화 패키지 법안'을 발의했다. 21대 국회 임기가 종료되면서 자동 폐기된 토큰증권 법제화가 재발의 된 것이다. 이번 발의안의 주요 내용으론 '온라인·디지털 플랫폼이 발전함에 따라 비정형적 증권인 투자계약증권에 대해서도 다수 투자자 간 유통시장이 형성될 수 있으므로, 투자계약증권은 발행 관련 규정에서만 자본시장법상 증권으로 보고 있는 단서'를 삭제한 것이다.</p></br>
<p>즉, 다른 증권과 동일하게 유통에 대한 규제를 적용받도록 한 것이다. 지난해 2월 금융위원회가 토큰증권 합법화를 공식화하고 시장조성 계획을 발표한 후 유관사들은 이를 발행하기 위해 준비를 서두르는 추세였다. 특히 지난 22대 국회에서 STO시장 개막을 위한 가이드라인이 완성됨에 따라, 내용 수정이나 검토 과정이 불필요한 만큼 시장 개막에 속도가 붙을 것으로 보인다.</p>
<p>토큰증권은 부동산·미술품·귀금속·저작권 등의 자산을 블록체인을 통해 디지털화해 증권화하는 것을 의미한다. 이는 자본시장법상 미술품 조각투자 상품인 투자계약증권과 부동산·음악 저작권 등의 조각투자 상품인 비금전신탁수익증권을 포함한다. 많은 종류의 자산을 증권 형태로 발행이 가능한 만큼, 금융권의 신사업 먹거리로 지목돼 왔다. 현재까지 인가된 조각투자 모델에는 부동산(실물)·음악저작권·부동산대출채권·항공기엔진(실물) 등이 있다.</p></br>
<p>토큰증권 시장 활성화에 대한 기대감이 높아지면서 한국거래소와 예탁결제원을 비롯해 증권사들도 활기를 찾고 있다. 앞서 한국거래소는 지난해 12월부터 신종증권시장 샌드박스(혁신금융서비스)를 지정, STO 장내 시장을 준비 중이다. 한국예탁결제원은 지난 8월, 사업예산 41억원 규모의 '토큰증권 플랫폼 구축 연구용역' 입찰공고를 냈다. 전자증권 등록기관인 예탁원이 당국의 방침에 따라 토큰증권 발행 관리 역할을 일임하기 때문이다.</p></br>
<p>이후 지난 9월에는 관련 업체와 토큰증권 테스트베드 플랫폼을 구축하기 위한 협약을 맺고 지난달부터 플랫폼 및 시스템 개발에 본격 착수했다. 예탁원은 △총량관리 △노드관리 △분산원장 등 세 개의 시스템으로 나누어 구성될 예정이다. 하나증권의 경우, STO 시장 진출을 위한 통합시스템 구축을 마무리한 것으로 알려진다.</p></br>
<p>STO 법제화가 통과될 경우 하나증권은 해당 시스템을 오픈하고 첫 조각투자 거래 상품으로 미술품을 선정, MTS 개편을 통해 조각투자 거래 서비스를 시행할 예정이다. 하나증권 관계자는 "토큰증권 발행·유통에 필요한 관련 시스템 구축은 모두 완료된 상태이다"며, "법안이 통과되고 나면 공개될 부분이 많고 현재는 시스템 완료 후 생태계 형성을 위해 타 업체들과 업무 협약을 맺어 준비하고 있다"고 말했다.</p></br>
<p>시장에서는 증권사들이 토큰증권 시장 개막이 향후 증권사들에 안정적인 수익을 가져올 것으로 보고 있다. 윤유동 NH투자증권 연구원은 "법안을 재발의했다는 점에서 토큰증권 제도화 의지가 강한 것으로 보인다"며, "남은 과제는 증권사들의 인프라 구축으로 미래에셋증권, 하나증권을 제외하면 아직 자체 토큰증권 인프라를 갖춘 증권사는 없지만, 대다수 증권사들이 입법 이후 작업에 착수하겠다는 입장인데 이미 관련 업체들과 협업 중인 바, 단기간 내 시스템 구축이 가능할 것이다"고 전망했다.</p></br>
<p>시장은 STO시장이 제도권으로 편입될 경우, 장외 시장에 있는 관련 기업들도 장내 시장으로 빠르게 편입할 수 있을 것으로 보고 있다. 특히 미술품과 같은 유형자산과 달리, 무형자산은 구조가 단순해 상장 속도가 빠를 것이란 의견이다. 이정두 한국금융연구원 선임연구위원은 "가상자산보다 토큰증권의 활용도가 더 높아 뱅카우, 서울옥션블루 등의 기업들의 발전 가능성이 크다고 본다"며, "제도적 판을 깔아서 실제로 이 기업들을 움직여 볼 수 있는 기회를 주는 것이 먼저일 것이다. 국회에서 토큰증권이 시장 장래성을 입증할 수 있도록 제도 정비를 서둘러 마무리 할 필요가 있다"고 말했다.</p></br>
`,
    img: `https://cdn.hansbiz.co.kr/news/photo/202411/721317_703502_3632.jpg`,
    location: `https://www.hansbiz.co.kr/news/articleView.html?idxno=721317`,
    date: `2024.11.17`,
    tags: [{ tag: `소식` }],
  },
  {
    id: "2",
    title: `(2) 증권사, 디지털 금융 전환 위해 'AI' 도입 박차..."기술 고도화 추진"`,
    summary: `리서치 발간·종목 추천 등, 투자자 편의성 제고에 초점
AI 기술 관한 적극 논의 진행 중...기술 고도화 주력 단계`,
    content: `
<p>[한스경제=박영선 기자] 팬데믹을 기점으로 디지털 기반의 비대면 금융이 중요한 영역으로 떠오르면서, 증권업 내에서도 디지털 금융의 주도권을 확보하기 위한 경쟁이 시작되고 있다. </p></br>
<p>이에 증권사들이 투자 정보·리서치 작성을 위주로  AI 기술을 도입하고 있으며, 투자자들은 AI 관련 서비스 영역 확장에 관심을 기울이고 있다.</p></br>
<p>우선 하나증권은 금융 데이터 시장정보 강화를 위해 지난 9월 LSEG런던증권거래소와 업무협약을 체결했다. LSEG런던증권거래소 그룹은 런던증권거래소 운영과 금융 데이터 사업 등을 영위하는회사로 세계 최대 규모 글로벌 금융정보 회사 중 하나다. 앞서 하나증권은 자사 MTS 원큐프로에 'AI 내부자 시그널' 서비스를 오픈, 국내외 공시데이터에서 내부자 거래 데이터를 실시간으로 제공하고 있다</p></br>
<p>지난해 모바일트레이딩시스템(MTS) 월평균 사용량 1위에 오른 KB증권은 올해 7월 AI 전담 부서인 'AI비즈추진유닛'을 신설했다. 또한 10월에는 AI 알고리즘을 기반으로 한 자산관리 투자일임 서비스인 'KB AI 시그널'을 출시한 데 이어, 이달 4일에는 생성형 AI를 활용한 국내외 종목 기업분석 보고서인 'AI 실적속보'를 발간했다. 'AI 실적속보'는 생성형AI 툴을 이용해 기업 실적발표를 요약하고 주요 포인트를 추출한 보고서로, 투자자들의 정보 접근성을 높이는 것이 목적이다.</p></br>
<p>퇴직연금 서비스 강자로 떠오른 미래에셋증권은 로보어드바이저 서비스를 M-STOCK를 통해 퇴직연금(DC·IRP)과 동일한 개인 맞춤형 설계를 개인연금에도 제공할 예정이다. 특히 퇴직연금 사업자가 자체 알고리즘을 개발해 업계 최초로 퇴직연금과 개인연금을 통합 제공하는 서비스를 도입한다.</p></br>
<p>NH투자증권은 국내 금융권 최초로 생성형 AI의 이미지 인식 기능을 활용한 서비스인 '차분이(차트 분석 AI)'를 출시했다. '차분이' 서비스는 고객이 보는 차트를 AI가 자동으로 설명해주고 이해가어려운 차트 속 정보의 분석 포인트를 짚어준다.</p></br>
<p>그동안 AI 기술을 활용한 서비스 출시가 대형 증권사 중심으로 이루어졌다면, 최근 중소형 증권사의 움직임도 활발해진 추세다</p></br>
`,
    img: `https://cdn.hansbiz.co.kr/news/photo/202411/721002_702986_1025.jpg`,
    location: `https://www.hansbiz.co.kr/news/articleView.html?idxno=721002`,
    date: `2024.11.15`,
    tags: [{ tag: `소식` }],
  },
  {
    id: "3",
    title: `(3) 신한투자증권, '프로젝트 펄스' 가동...대한민국 토큰증권 기준 제시`,
    summary: `블록체인글로벌과 공동 추진...블록체인 분산원장 인프라 구축 완료`,
    content: `
<p>[한스경제=박영선 기자] 신한투자증권은 블록체인글로벌과 공동으로 추진 중인 프로젝트 펄스(PULSE)가 블록체인 분산원장 인프라 구축을 마치고 본격적인 가동에 돌입했다고 11일 밝혔다.</p></br>
<p>첫 사례로, 펄스는 조각투자사업자와 혁신금융서비스 사업자를 대상으로 토큰증권 발행과 유통에 필요한 블록체인 기반의 분산원장 인프라와 함께 금융/법률 솔루션을 통합한 ‘원스톱 서비스’를 제공한다.</p></br>
<p>펄스는 신한투자증권과 SK증권, 블록체인글로벌, 법무법인(유) 광장이 협업해 금융 시장에 블록체인 기술을 도입하기 위해 탄생한 혁신적인 이니셔티브다</p></br>
<p>특히, 이번에 완료된 분산원장 인프라는 참여자 간 이해상충 방지와 투자자 보호를 위해 토큰증권 발행과 유통의 구조적 분리를 전제로 복수의 계좌관리기관이 주요 노드를 구성했다. 이는 금융위원회가 발표한 ‘토큰증권 가이드라인’의 분산원장 핵심 요건을 충족하는 것이다. 또한, 법률 정비에 따라 세부 요건에 맞춰 지속적으로 업데이트를 제공한다.</p></br>
<p>신한투자증권은 지난 4월 혁신금융서비스 지정을 받은 갤럭시아머니트리의 항공기 엔진 신탁수익증권 발행에 펄스에서 구축한 인프라를 제공하는 것을 시작으로 다양한 조각투자 사업자와 혁신금융서비스 사업자들에게 본 서비스를 제공할 예정이다.</p></br>
<p>프로젝트 펄스의 관계자는 “토큰증권 시장이 이제 막 발걸음을 뗀 만큼 증권사 등의 금융기관뿐 아니라 일반 사업자는 초기 비용이 많이 드는 개별 인프라 구축보다는 펄스와 같은 검증된 구독형 과금모델을 선택하는 것이 비용 효율성 측면에서 합리적인 선택”이라며, “프로젝트 펄스가 현재 진행 중인 국내 토큰증권 법제화 과정에 기여할 수 있길 기대한다”라고 언급했다.</p></br>
`,
    img: `https://cdn.hansbiz.co.kr/news/photo/202411/720259_701916_5817.jpg`,
    location: `https://www.hansbiz.co.kr/news/articleView.html?idxno=720259`,
    date: `2024.11.11`,
    tags: [{ tag: `소식` }],
  },
  {
    id: "4",
    title: `(4) 증권사, 디지털 금융 전환 위해 'AI' 도입 박차..."기술 고도화 추진"`,
    summary: `리서치 발간·종목 추천 등, 투자자 편의성 제고에 초점
AI 기술 관한 적극 논의 진행 중...기술 고도화 주력 단계`,
    content: `
<p>[한스경제=박영선 기자] 팬데믹을 기점으로 디지털 기반의 비대면 금융이 중요한 영역으로 떠오르면서, 증권업 내에서도 디지털 금융의 주도권을 확보하기 위한 경쟁이 시작되고 있다. </p></br>
<p>이에 증권사들이 투자 정보·리서치 작성을 위주로  AI 기술을 도입하고 있으며, 투자자들은 AI 관련 서비스 영역 확장에 관심을 기울이고 있다.</p></br>
<p>우선 하나증권은 금융 데이터 시장정보 강화를 위해 지난 9월 LSEG런던증권거래소와 업무협약을 체결했다. LSEG런던증권거래소 그룹은 런던증권거래소 운영과 금융 데이터 사업 등을 영위하는회사로 세계 최대 규모 글로벌 금융정보 회사 중 하나다. 앞서 하나증권은 자사 MTS 원큐프로에 'AI 내부자 시그널' 서비스를 오픈, 국내외 공시데이터에서 내부자 거래 데이터를 실시간으로 제공하고 있다</p></br>
<p>지난해 모바일트레이딩시스템(MTS) 월평균 사용량 1위에 오른 KB증권은 올해 7월 AI 전담 부서인 'AI비즈추진유닛'을 신설했다. 또한 10월에는 AI 알고리즘을 기반으로 한 자산관리 투자일임 서비스인 'KB AI 시그널'을 출시한 데 이어, 이달 4일에는 생성형 AI를 활용한 국내외 종목 기업분석 보고서인 'AI 실적속보'를 발간했다. 'AI 실적속보'는 생성형AI 툴을 이용해 기업 실적발표를 요약하고 주요 포인트를 추출한 보고서로, 투자자들의 정보 접근성을 높이는 것이 목적이다.</p></br>
<p>퇴직연금 서비스 강자로 떠오른 미래에셋증권은 로보어드바이저 서비스를 M-STOCK를 통해 퇴직연금(DC·IRP)과 동일한 개인 맞춤형 설계를 개인연금에도 제공할 예정이다. 특히 퇴직연금 사업자가 자체 알고리즘을 개발해 업계 최초로 퇴직연금과 개인연금을 통합 제공하는 서비스를 도입한다.</p></br>
<p>NH투자증권은 국내 금융권 최초로 생성형 AI의 이미지 인식 기능을 활용한 서비스인 '차분이(차트 분석 AI)'를 출시했다. '차분이' 서비스는 고객이 보는 차트를 AI가 자동으로 설명해주고 이해가어려운 차트 속 정보의 분석 포인트를 짚어준다.</p></br>
<p>그동안 AI 기술을 활용한 서비스 출시가 대형 증권사 중심으로 이루어졌다면, 최근 중소형 증권사의 움직임도 활발해진 추세다</p></br>
`,
    img: `https://cdn.hansbiz.co.kr/news/photo/202411/721002_702986_1025.jpg`,
    location: `https://www.hansbiz.co.kr/news/articleView.html?idxno=721002`,
    date: `2024.11.15`,
    tags: [{ tag: `소식` }],
  },
  {
    id: "5",
    title: `(5) 신한투자증권, '프로젝트 펄스' 가동...대한민국 토큰증권 기준 제시`,
    summary: `블록체인글로벌과 공동 추진...블록체인 분산원장 인프라 구축 완료`,
    content: `
<p>[한스경제=박영선 기자] 신한투자증권은 블록체인글로벌과 공동으로 추진 중인 프로젝트 펄스(PULSE)가 블록체인 분산원장 인프라 구축을 마치고 본격적인 가동에 돌입했다고 11일 밝혔다.</p></br>
<p>첫 사례로, 펄스는 조각투자사업자와 혁신금융서비스 사업자를 대상으로 토큰증권 발행과 유통에 필요한 블록체인 기반의 분산원장 인프라와 함께 금융/법률 솔루션을 통합한 ‘원스톱 서비스’를 제공한다.</p></br>
<p>펄스는 신한투자증권과 SK증권, 블록체인글로벌, 법무법인(유) 광장이 협업해 금융 시장에 블록체인 기술을 도입하기 위해 탄생한 혁신적인 이니셔티브다</p></br>
<p>특히, 이번에 완료된 분산원장 인프라는 참여자 간 이해상충 방지와 투자자 보호를 위해 토큰증권 발행과 유통의 구조적 분리를 전제로 복수의 계좌관리기관이 주요 노드를 구성했다. 이는 금융위원회가 발표한 ‘토큰증권 가이드라인’의 분산원장 핵심 요건을 충족하는 것이다. 또한, 법률 정비에 따라 세부 요건에 맞춰 지속적으로 업데이트를 제공한다.</p></br>
<p>신한투자증권은 지난 4월 혁신금융서비스 지정을 받은 갤럭시아머니트리의 항공기 엔진 신탁수익증권 발행에 펄스에서 구축한 인프라를 제공하는 것을 시작으로 다양한 조각투자 사업자와 혁신금융서비스 사업자들에게 본 서비스를 제공할 예정이다.</p></br>
<p>프로젝트 펄스의 관계자는 “토큰증권 시장이 이제 막 발걸음을 뗀 만큼 증권사 등의 금융기관뿐 아니라 일반 사업자는 초기 비용이 많이 드는 개별 인프라 구축보다는 펄스와 같은 검증된 구독형 과금모델을 선택하는 것이 비용 효율성 측면에서 합리적인 선택”이라며, “프로젝트 펄스가 현재 진행 중인 국내 토큰증권 법제화 과정에 기여할 수 있길 기대한다”라고 언급했다.</p></br>
`,
    img: `https://cdn.hansbiz.co.kr/news/photo/202411/720259_701916_5817.jpg`,
    location: `https://www.hansbiz.co.kr/news/articleView.html?idxno=720259`,
    date: `2024.11.11`,
    tags: [{ tag: `소식` }],
  },
  {
    id: "6",
    title: `(6) 재부상한 '토큰증권' 시장...추진 속도 불 붙을까`,
    summary: `시장 개막 대기하며 준비 서두르는 한국거래소·예탁결제원 하나증권, 토큰증권 발행·유통 준비 완료`,
    content: `
<p>[한스경제=박영선 기자] 토큰증권 법제화가 국회를 통과되면서 토큰증권 발행(STO) 시장 활성화에 대한 기대감이 높아졌다. 이에 토큰증권 시장을 향한 정치권의 지지가 확인되면서 관련사들의 움직임도 활발해지고 있다.</p></br>
<p>지난달 25일 정무위원회 소속 김재섭 국민의힘 의원이 'STO 제도화 패키지 법안'을 발의했다. 21대 국회 임기가 종료되면서 자동 폐기된 토큰증권 법제화가 재발의 된 것이다. 이번 발의안의 주요 내용으론 '온라인·디지털 플랫폼이 발전함에 따라 비정형적 증권인 투자계약증권에 대해서도 다수 투자자 간 유통시장이 형성될 수 있으므로, 투자계약증권은 발행 관련 규정에서만 자본시장법상 증권으로 보고 있는 단서'를 삭제한 것이다.</p></br>
<p>즉, 다른 증권과 동일하게 유통에 대한 규제를 적용받도록 한 것이다. 지난해 2월 금융위원회가 토큰증권 합법화를 공식화하고 시장조성 계획을 발표한 후 유관사들은 이를 발행하기 위해 준비를 서두르는 추세였다. 특히 지난 22대 국회에서 STO시장 개막을 위한 가이드라인이 완성됨에 따라, 내용 수정이나 검토 과정이 불필요한 만큼 시장 개막에 속도가 붙을 것으로 보인다.</p>
<p>토큰증권은 부동산·미술품·귀금속·저작권 등의 자산을 블록체인을 통해 디지털화해 증권화하는 것을 의미한다. 이는 자본시장법상 미술품 조각투자 상품인 투자계약증권과 부동산·음악 저작권 등의 조각투자 상품인 비금전신탁수익증권을 포함한다. 많은 종류의 자산을 증권 형태로 발행이 가능한 만큼, 금융권의 신사업 먹거리로 지목돼 왔다. 현재까지 인가된 조각투자 모델에는 부동산(실물)·음악저작권·부동산대출채권·항공기엔진(실물) 등이 있다.</p></br>
<p>토큰증권 시장 활성화에 대한 기대감이 높아지면서 한국거래소와 예탁결제원을 비롯해 증권사들도 활기를 찾고 있다. 앞서 한국거래소는 지난해 12월부터 신종증권시장 샌드박스(혁신금융서비스)를 지정, STO 장내 시장을 준비 중이다. 한국예탁결제원은 지난 8월, 사업예산 41억원 규모의 '토큰증권 플랫폼 구축 연구용역' 입찰공고를 냈다. 전자증권 등록기관인 예탁원이 당국의 방침에 따라 토큰증권 발행 관리 역할을 일임하기 때문이다.</p></br>
<p>이후 지난 9월에는 관련 업체와 토큰증권 테스트베드 플랫폼을 구축하기 위한 협약을 맺고 지난달부터 플랫폼 및 시스템 개발에 본격 착수했다. 예탁원은 △총량관리 △노드관리 △분산원장 등 세 개의 시스템으로 나누어 구성될 예정이다. 하나증권의 경우, STO 시장 진출을 위한 통합시스템 구축을 마무리한 것으로 알려진다.</p></br>
<p>STO 법제화가 통과될 경우 하나증권은 해당 시스템을 오픈하고 첫 조각투자 거래 상품으로 미술품을 선정, MTS 개편을 통해 조각투자 거래 서비스를 시행할 예정이다. 하나증권 관계자는 "토큰증권 발행·유통에 필요한 관련 시스템 구축은 모두 완료된 상태이다"며, "법안이 통과되고 나면 공개될 부분이 많고 현재는 시스템 완료 후 생태계 형성을 위해 타 업체들과 업무 협약을 맺어 준비하고 있다"고 말했다.</p></br>
<p>시장에서는 증권사들이 토큰증권 시장 개막이 향후 증권사들에 안정적인 수익을 가져올 것으로 보고 있다. 윤유동 NH투자증권 연구원은 "법안을 재발의했다는 점에서 토큰증권 제도화 의지가 강한 것으로 보인다"며, "남은 과제는 증권사들의 인프라 구축으로 미래에셋증권, 하나증권을 제외하면 아직 자체 토큰증권 인프라를 갖춘 증권사는 없지만, 대다수 증권사들이 입법 이후 작업에 착수하겠다는 입장인데 이미 관련 업체들과 협업 중인 바, 단기간 내 시스템 구축이 가능할 것이다"고 전망했다.</p></br>
<p>시장은 STO시장이 제도권으로 편입될 경우, 장외 시장에 있는 관련 기업들도 장내 시장으로 빠르게 편입할 수 있을 것으로 보고 있다. 특히 미술품과 같은 유형자산과 달리, 무형자산은 구조가 단순해 상장 속도가 빠를 것이란 의견이다. 이정두 한국금융연구원 선임연구위원은 "가상자산보다 토큰증권의 활용도가 더 높아 뱅카우, 서울옥션블루 등의 기업들의 발전 가능성이 크다고 본다"며, "제도적 판을 깔아서 실제로 이 기업들을 움직여 볼 수 있는 기회를 주는 것이 먼저일 것이다. 국회에서 토큰증권이 시장 장래성을 입증할 수 있도록 제도 정비를 서둘러 마무리 할 필요가 있다"고 말했다.</p></br>
`,
    img: `https://cdn.hansbiz.co.kr/news/photo/202411/721317_703502_3632.jpg`,
    date: `2024.11.17`,
    tags: [{ tag: `뉴스` }],
  },
  {
    id: "7",
    title: `(7) 재부상한 '토큰증권' 시장...추진 속도 불 붙을까`,
    summary: ``,
    content: `
<p>[한스경제=박영선 기자] 토큰증권 법제화가 국회를 통과되면서 토큰증권 발행(STO) 시장 활성화에 대한 기대감이 높아졌다. 이에 토큰증권 시장을 향한 정치권의 지지가 확인되면서 관련사들의 움직임도 활발해지고 있다.</p></br>
<p>지난달 25일 정무위원회 소속 김재섭 국민의힘 의원이 'STO 제도화 패키지 법안'을 발의했다. 21대 국회 임기가 종료되면서 자동 폐기된 토큰증권 법제화가 재발의 된 것이다. 이번 발의안의 주요 내용으론 '온라인·디지털 플랫폼이 발전함에 따라 비정형적 증권인 투자계약증권에 대해서도 다수 투자자 간 유통시장이 형성될 수 있으므로, 투자계약증권은 발행 관련 규정에서만 자본시장법상 증권으로 보고 있는 단서'를 삭제한 것이다.</p></br>
<p>즉, 다른 증권과 동일하게 유통에 대한 규제를 적용받도록 한 것이다. 지난해 2월 금융위원회가 토큰증권 합법화를 공식화하고 시장조성 계획을 발표한 후 유관사들은 이를 발행하기 위해 준비를 서두르는 추세였다. 특히 지난 22대 국회에서 STO시장 개막을 위한 가이드라인이 완성됨에 따라, 내용 수정이나 검토 과정이 불필요한 만큼 시장 개막에 속도가 붙을 것으로 보인다.</p>
<p>토큰증권은 부동산·미술품·귀금속·저작권 등의 자산을 블록체인을 통해 디지털화해 증권화하는 것을 의미한다. 이는 자본시장법상 미술품 조각투자 상품인 투자계약증권과 부동산·음악 저작권 등의 조각투자 상품인 비금전신탁수익증권을 포함한다. 많은 종류의 자산을 증권 형태로 발행이 가능한 만큼, 금융권의 신사업 먹거리로 지목돼 왔다. 현재까지 인가된 조각투자 모델에는 부동산(실물)·음악저작권·부동산대출채권·항공기엔진(실물) 등이 있다.</p></br>
<p>토큰증권 시장 활성화에 대한 기대감이 높아지면서 한국거래소와 예탁결제원을 비롯해 증권사들도 활기를 찾고 있다. 앞서 한국거래소는 지난해 12월부터 신종증권시장 샌드박스(혁신금융서비스)를 지정, STO 장내 시장을 준비 중이다. 한국예탁결제원은 지난 8월, 사업예산 41억원 규모의 '토큰증권 플랫폼 구축 연구용역' 입찰공고를 냈다. 전자증권 등록기관인 예탁원이 당국의 방침에 따라 토큰증권 발행 관리 역할을 일임하기 때문이다.</p></br>
<p>이후 지난 9월에는 관련 업체와 토큰증권 테스트베드 플랫폼을 구축하기 위한 협약을 맺고 지난달부터 플랫폼 및 시스템 개발에 본격 착수했다. 예탁원은 △총량관리 △노드관리 △분산원장 등 세 개의 시스템으로 나누어 구성될 예정이다. 하나증권의 경우, STO 시장 진출을 위한 통합시스템 구축을 마무리한 것으로 알려진다.</p></br>
<p>STO 법제화가 통과될 경우 하나증권은 해당 시스템을 오픈하고 첫 조각투자 거래 상품으로 미술품을 선정, MTS 개편을 통해 조각투자 거래 서비스를 시행할 예정이다. 하나증권 관계자는 "토큰증권 발행·유통에 필요한 관련 시스템 구축은 모두 완료된 상태이다"며, "법안이 통과되고 나면 공개될 부분이 많고 현재는 시스템 완료 후 생태계 형성을 위해 타 업체들과 업무 협약을 맺어 준비하고 있다"고 말했다.</p></br>
<p>시장에서는 증권사들이 토큰증권 시장 개막이 향후 증권사들에 안정적인 수익을 가져올 것으로 보고 있다. 윤유동 NH투자증권 연구원은 "법안을 재발의했다는 점에서 토큰증권 제도화 의지가 강한 것으로 보인다"며, "남은 과제는 증권사들의 인프라 구축으로 미래에셋증권, 하나증권을 제외하면 아직 자체 토큰증권 인프라를 갖춘 증권사는 없지만, 대다수 증권사들이 입법 이후 작업에 착수하겠다는 입장인데 이미 관련 업체들과 협업 중인 바, 단기간 내 시스템 구축이 가능할 것이다"고 전망했다.</p></br>
<p>시장은 STO시장이 제도권으로 편입될 경우, 장외 시장에 있는 관련 기업들도 장내 시장으로 빠르게 편입할 수 있을 것으로 보고 있다. 특히 미술품과 같은 유형자산과 달리, 무형자산은 구조가 단순해 상장 속도가 빠를 것이란 의견이다. 이정두 한국금융연구원 선임연구위원은 "가상자산보다 토큰증권의 활용도가 더 높아 뱅카우, 서울옥션블루 등의 기업들의 발전 가능성이 크다고 본다"며, "제도적 판을 깔아서 실제로 이 기업들을 움직여 볼 수 있는 기회를 주는 것이 먼저일 것이다. 국회에서 토큰증권이 시장 장래성을 입증할 수 있도록 제도 정비를 서둘러 마무리 할 필요가 있다"고 말했다.</p></br>
`,
    img: `https://cdn.hansbiz.co.kr/news/photo/202411/721317_703502_3632.jpg`,
    date: `2024.11.17`,
    tags: [{ tag: `뉴스` }],
  },
  {
    id: "8",
    title: `(8) 재부상한 '토큰증권' 시장...추진 속도 불 붙을까`,
    summary: ``,
    content: `
<p>[한스경제=박영선 기자] 토큰증권 법제화가 국회를 통과되면서 토큰증권 발행(STO) 시장 활성화에 대한 기대감이 높아졌다. 이에 토큰증권 시장을 향한 정치권의 지지가 확인되면서 관련사들의 움직임도 활발해지고 있다.</p></br>
<p>지난달 25일 정무위원회 소속 김재섭 국민의힘 의원이 'STO 제도화 패키지 법안'을 발의했다. 21대 국회 임기가 종료되면서 자동 폐기된 토큰증권 법제화가 재발의 된 것이다. 이번 발의안의 주요 내용으론 '온라인·디지털 플랫폼이 발전함에 따라 비정형적 증권인 투자계약증권에 대해서도 다수 투자자 간 유통시장이 형성될 수 있으므로, 투자계약증권은 발행 관련 규정에서만 자본시장법상 증권으로 보고 있는 단서'를 삭제한 것이다.</p></br>
<p>즉, 다른 증권과 동일하게 유통에 대한 규제를 적용받도록 한 것이다. 지난해 2월 금융위원회가 토큰증권 합법화를 공식화하고 시장조성 계획을 발표한 후 유관사들은 이를 발행하기 위해 준비를 서두르는 추세였다. 특히 지난 22대 국회에서 STO시장 개막을 위한 가이드라인이 완성됨에 따라, 내용 수정이나 검토 과정이 불필요한 만큼 시장 개막에 속도가 붙을 것으로 보인다.</p>
<p>토큰증권은 부동산·미술품·귀금속·저작권 등의 자산을 블록체인을 통해 디지털화해 증권화하는 것을 의미한다. 이는 자본시장법상 미술품 조각투자 상품인 투자계약증권과 부동산·음악 저작권 등의 조각투자 상품인 비금전신탁수익증권을 포함한다. 많은 종류의 자산을 증권 형태로 발행이 가능한 만큼, 금융권의 신사업 먹거리로 지목돼 왔다. 현재까지 인가된 조각투자 모델에는 부동산(실물)·음악저작권·부동산대출채권·항공기엔진(실물) 등이 있다.</p></br>
<p>토큰증권 시장 활성화에 대한 기대감이 높아지면서 한국거래소와 예탁결제원을 비롯해 증권사들도 활기를 찾고 있다. 앞서 한국거래소는 지난해 12월부터 신종증권시장 샌드박스(혁신금융서비스)를 지정, STO 장내 시장을 준비 중이다. 한국예탁결제원은 지난 8월, 사업예산 41억원 규모의 '토큰증권 플랫폼 구축 연구용역' 입찰공고를 냈다. 전자증권 등록기관인 예탁원이 당국의 방침에 따라 토큰증권 발행 관리 역할을 일임하기 때문이다.</p></br>
<p>이후 지난 9월에는 관련 업체와 토큰증권 테스트베드 플랫폼을 구축하기 위한 협약을 맺고 지난달부터 플랫폼 및 시스템 개발에 본격 착수했다. 예탁원은 △총량관리 △노드관리 △분산원장 등 세 개의 시스템으로 나누어 구성될 예정이다. 하나증권의 경우, STO 시장 진출을 위한 통합시스템 구축을 마무리한 것으로 알려진다.</p></br>
<p>STO 법제화가 통과될 경우 하나증권은 해당 시스템을 오픈하고 첫 조각투자 거래 상품으로 미술품을 선정, MTS 개편을 통해 조각투자 거래 서비스를 시행할 예정이다. 하나증권 관계자는 "토큰증권 발행·유통에 필요한 관련 시스템 구축은 모두 완료된 상태이다"며, "법안이 통과되고 나면 공개될 부분이 많고 현재는 시스템 완료 후 생태계 형성을 위해 타 업체들과 업무 협약을 맺어 준비하고 있다"고 말했다.</p></br>
<p>시장에서는 증권사들이 토큰증권 시장 개막이 향후 증권사들에 안정적인 수익을 가져올 것으로 보고 있다. 윤유동 NH투자증권 연구원은 "법안을 재발의했다는 점에서 토큰증권 제도화 의지가 강한 것으로 보인다"며, "남은 과제는 증권사들의 인프라 구축으로 미래에셋증권, 하나증권을 제외하면 아직 자체 토큰증권 인프라를 갖춘 증권사는 없지만, 대다수 증권사들이 입법 이후 작업에 착수하겠다는 입장인데 이미 관련 업체들과 협업 중인 바, 단기간 내 시스템 구축이 가능할 것이다"고 전망했다.</p></br>
<p>시장은 STO시장이 제도권으로 편입될 경우, 장외 시장에 있는 관련 기업들도 장내 시장으로 빠르게 편입할 수 있을 것으로 보고 있다. 특히 미술품과 같은 유형자산과 달리, 무형자산은 구조가 단순해 상장 속도가 빠를 것이란 의견이다. 이정두 한국금융연구원 선임연구위원은 "가상자산보다 토큰증권의 활용도가 더 높아 뱅카우, 서울옥션블루 등의 기업들의 발전 가능성이 크다고 본다"며, "제도적 판을 깔아서 실제로 이 기업들을 움직여 볼 수 있는 기회를 주는 것이 먼저일 것이다. 국회에서 토큰증권이 시장 장래성을 입증할 수 있도록 제도 정비를 서둘러 마무리 할 필요가 있다"고 말했다.</p></br>
`,
    img: `https://cdn.hansbiz.co.kr/news/photo/202411/721317_703502_3632.jpg`,
    date: `2024.11.17`,
    tags: [{ tag: `뉴스` }],
  },
  {
    id: "9",
    title: `(9) 재부상한 '토큰증권' 시장...추진 속도 불 붙을까`,
    summary: ``,
    content: `
<p>[한스경제=박영선 기자] 토큰증권 법제화가 국회를 통과되면서 토큰증권 발행(STO) 시장 활성화에 대한 기대감이 높아졌다. 이에 토큰증권 시장을 향한 정치권의 지지가 확인되면서 관련사들의 움직임도 활발해지고 있다.</p></br>
<p>지난달 25일 정무위원회 소속 김재섭 국민의힘 의원이 'STO 제도화 패키지 법안'을 발의했다. 21대 국회 임기가 종료되면서 자동 폐기된 토큰증권 법제화가 재발의 된 것이다. 이번 발의안의 주요 내용으론 '온라인·디지털 플랫폼이 발전함에 따라 비정형적 증권인 투자계약증권에 대해서도 다수 투자자 간 유통시장이 형성될 수 있으므로, 투자계약증권은 발행 관련 규정에서만 자본시장법상 증권으로 보고 있는 단서'를 삭제한 것이다.</p></br>
<p>즉, 다른 증권과 동일하게 유통에 대한 규제를 적용받도록 한 것이다. 지난해 2월 금융위원회가 토큰증권 합법화를 공식화하고 시장조성 계획을 발표한 후 유관사들은 이를 발행하기 위해 준비를 서두르는 추세였다. 특히 지난 22대 국회에서 STO시장 개막을 위한 가이드라인이 완성됨에 따라, 내용 수정이나 검토 과정이 불필요한 만큼 시장 개막에 속도가 붙을 것으로 보인다.</p>
<p>토큰증권은 부동산·미술품·귀금속·저작권 등의 자산을 블록체인을 통해 디지털화해 증권화하는 것을 의미한다. 이는 자본시장법상 미술품 조각투자 상품인 투자계약증권과 부동산·음악 저작권 등의 조각투자 상품인 비금전신탁수익증권을 포함한다. 많은 종류의 자산을 증권 형태로 발행이 가능한 만큼, 금융권의 신사업 먹거리로 지목돼 왔다. 현재까지 인가된 조각투자 모델에는 부동산(실물)·음악저작권·부동산대출채권·항공기엔진(실물) 등이 있다.</p></br>
<p>토큰증권 시장 활성화에 대한 기대감이 높아지면서 한국거래소와 예탁결제원을 비롯해 증권사들도 활기를 찾고 있다. 앞서 한국거래소는 지난해 12월부터 신종증권시장 샌드박스(혁신금융서비스)를 지정, STO 장내 시장을 준비 중이다. 한국예탁결제원은 지난 8월, 사업예산 41억원 규모의 '토큰증권 플랫폼 구축 연구용역' 입찰공고를 냈다. 전자증권 등록기관인 예탁원이 당국의 방침에 따라 토큰증권 발행 관리 역할을 일임하기 때문이다.</p></br>
<p>이후 지난 9월에는 관련 업체와 토큰증권 테스트베드 플랫폼을 구축하기 위한 협약을 맺고 지난달부터 플랫폼 및 시스템 개발에 본격 착수했다. 예탁원은 △총량관리 △노드관리 △분산원장 등 세 개의 시스템으로 나누어 구성될 예정이다. 하나증권의 경우, STO 시장 진출을 위한 통합시스템 구축을 마무리한 것으로 알려진다.</p></br>
<p>STO 법제화가 통과될 경우 하나증권은 해당 시스템을 오픈하고 첫 조각투자 거래 상품으로 미술품을 선정, MTS 개편을 통해 조각투자 거래 서비스를 시행할 예정이다. 하나증권 관계자는 "토큰증권 발행·유통에 필요한 관련 시스템 구축은 모두 완료된 상태이다"며, "법안이 통과되고 나면 공개될 부분이 많고 현재는 시스템 완료 후 생태계 형성을 위해 타 업체들과 업무 협약을 맺어 준비하고 있다"고 말했다.</p></br>
<p>시장에서는 증권사들이 토큰증권 시장 개막이 향후 증권사들에 안정적인 수익을 가져올 것으로 보고 있다. 윤유동 NH투자증권 연구원은 "법안을 재발의했다는 점에서 토큰증권 제도화 의지가 강한 것으로 보인다"며, "남은 과제는 증권사들의 인프라 구축으로 미래에셋증권, 하나증권을 제외하면 아직 자체 토큰증권 인프라를 갖춘 증권사는 없지만, 대다수 증권사들이 입법 이후 작업에 착수하겠다는 입장인데 이미 관련 업체들과 협업 중인 바, 단기간 내 시스템 구축이 가능할 것이다"고 전망했다.</p></br>
<p>시장은 STO시장이 제도권으로 편입될 경우, 장외 시장에 있는 관련 기업들도 장내 시장으로 빠르게 편입할 수 있을 것으로 보고 있다. 특히 미술품과 같은 유형자산과 달리, 무형자산은 구조가 단순해 상장 속도가 빠를 것이란 의견이다. 이정두 한국금융연구원 선임연구위원은 "가상자산보다 토큰증권의 활용도가 더 높아 뱅카우, 서울옥션블루 등의 기업들의 발전 가능성이 크다고 본다"며, "제도적 판을 깔아서 실제로 이 기업들을 움직여 볼 수 있는 기회를 주는 것이 먼저일 것이다. 국회에서 토큰증권이 시장 장래성을 입증할 수 있도록 제도 정비를 서둘러 마무리 할 필요가 있다"고 말했다.</p></br>
`,
    img: `https://cdn.hansbiz.co.kr/news/photo/202411/721317_703502_3632.jpg`,
    date: `2024.11.17`,
    tags: [{ tag: `뉴스` }],
  },
  {
    id: "10",
    title: `(10) 재부상한 '토큰증권' 시장...추진 속도 불 붙을까`,
    summary: ``,
    content: `
<p>[한스경제=박영선 기자] 토큰증권 법제화가 국회를 통과되면서 토큰증권 발행(STO) 시장 활성화에 대한 기대감이 높아졌다. 이에 토큰증권 시장을 향한 정치권의 지지가 확인되면서 관련사들의 움직임도 활발해지고 있다.</p></br>
<p>지난달 25일 정무위원회 소속 김재섭 국민의힘 의원이 'STO 제도화 패키지 법안'을 발의했다. 21대 국회 임기가 종료되면서 자동 폐기된 토큰증권 법제화가 재발의 된 것이다. 이번 발의안의 주요 내용으론 '온라인·디지털 플랫폼이 발전함에 따라 비정형적 증권인 투자계약증권에 대해서도 다수 투자자 간 유통시장이 형성될 수 있으므로, 투자계약증권은 발행 관련 규정에서만 자본시장법상 증권으로 보고 있는 단서'를 삭제한 것이다.</p></br>
<p>즉, 다른 증권과 동일하게 유통에 대한 규제를 적용받도록 한 것이다. 지난해 2월 금융위원회가 토큰증권 합법화를 공식화하고 시장조성 계획을 발표한 후 유관사들은 이를 발행하기 위해 준비를 서두르는 추세였다. 특히 지난 22대 국회에서 STO시장 개막을 위한 가이드라인이 완성됨에 따라, 내용 수정이나 검토 과정이 불필요한 만큼 시장 개막에 속도가 붙을 것으로 보인다.</p>
<p>토큰증권은 부동산·미술품·귀금속·저작권 등의 자산을 블록체인을 통해 디지털화해 증권화하는 것을 의미한다. 이는 자본시장법상 미술품 조각투자 상품인 투자계약증권과 부동산·음악 저작권 등의 조각투자 상품인 비금전신탁수익증권을 포함한다. 많은 종류의 자산을 증권 형태로 발행이 가능한 만큼, 금융권의 신사업 먹거리로 지목돼 왔다. 현재까지 인가된 조각투자 모델에는 부동산(실물)·음악저작권·부동산대출채권·항공기엔진(실물) 등이 있다.</p></br>
<p>토큰증권 시장 활성화에 대한 기대감이 높아지면서 한국거래소와 예탁결제원을 비롯해 증권사들도 활기를 찾고 있다. 앞서 한국거래소는 지난해 12월부터 신종증권시장 샌드박스(혁신금융서비스)를 지정, STO 장내 시장을 준비 중이다. 한국예탁결제원은 지난 8월, 사업예산 41억원 규모의 '토큰증권 플랫폼 구축 연구용역' 입찰공고를 냈다. 전자증권 등록기관인 예탁원이 당국의 방침에 따라 토큰증권 발행 관리 역할을 일임하기 때문이다.</p></br>
<p>이후 지난 9월에는 관련 업체와 토큰증권 테스트베드 플랫폼을 구축하기 위한 협약을 맺고 지난달부터 플랫폼 및 시스템 개발에 본격 착수했다. 예탁원은 △총량관리 △노드관리 △분산원장 등 세 개의 시스템으로 나누어 구성될 예정이다. 하나증권의 경우, STO 시장 진출을 위한 통합시스템 구축을 마무리한 것으로 알려진다.</p></br>
<p>STO 법제화가 통과될 경우 하나증권은 해당 시스템을 오픈하고 첫 조각투자 거래 상품으로 미술품을 선정, MTS 개편을 통해 조각투자 거래 서비스를 시행할 예정이다. 하나증권 관계자는 "토큰증권 발행·유통에 필요한 관련 시스템 구축은 모두 완료된 상태이다"며, "법안이 통과되고 나면 공개될 부분이 많고 현재는 시스템 완료 후 생태계 형성을 위해 타 업체들과 업무 협약을 맺어 준비하고 있다"고 말했다.</p></br>
<p>시장에서는 증권사들이 토큰증권 시장 개막이 향후 증권사들에 안정적인 수익을 가져올 것으로 보고 있다. 윤유동 NH투자증권 연구원은 "법안을 재발의했다는 점에서 토큰증권 제도화 의지가 강한 것으로 보인다"며, "남은 과제는 증권사들의 인프라 구축으로 미래에셋증권, 하나증권을 제외하면 아직 자체 토큰증권 인프라를 갖춘 증권사는 없지만, 대다수 증권사들이 입법 이후 작업에 착수하겠다는 입장인데 이미 관련 업체들과 협업 중인 바, 단기간 내 시스템 구축이 가능할 것이다"고 전망했다.</p></br>
<p>시장은 STO시장이 제도권으로 편입될 경우, 장외 시장에 있는 관련 기업들도 장내 시장으로 빠르게 편입할 수 있을 것으로 보고 있다. 특히 미술품과 같은 유형자산과 달리, 무형자산은 구조가 단순해 상장 속도가 빠를 것이란 의견이다. 이정두 한국금융연구원 선임연구위원은 "가상자산보다 토큰증권의 활용도가 더 높아 뱅카우, 서울옥션블루 등의 기업들의 발전 가능성이 크다고 본다"며, "제도적 판을 깔아서 실제로 이 기업들을 움직여 볼 수 있는 기회를 주는 것이 먼저일 것이다. 국회에서 토큰증권이 시장 장래성을 입증할 수 있도록 제도 정비를 서둘러 마무리 할 필요가 있다"고 말했다.</p></br>
`,
    img: `https://cdn.hansbiz.co.kr/news/photo/202411/721317_703502_3632.jpg`,
    date: `2024.11.17`,
    tags: [{ tag: `뉴스` }],
  },
];
