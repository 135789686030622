import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowWidth from "../../hooks/useWindowWidth";

function ServiceBanner() {
  const { t } = useTranslation();

  const [mobile, setMobile] = useState(false);
  const viewWidth = useWindowWidth(0);

  useEffect(() => {
    if (viewWidth <= 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [viewWidth]);

  return (
    <div className="banner_wrapper">
      <img
        src={
          mobile
            ? "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/acb5c884-b623-45d1-4c3d-438cbcd08200/companybanner"
            : "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/d60dc493-cfc6-4a85-ee60-bac55d193000/banner"
        }
        alt="Service Banner"
        className={mobile ? "mobile_service_banner_img" : "banner_img"}
        loading="lazy"
        decoding="async"
      />
      <div className="banner_content_box">
        <div className="nav_content">Home · Service</div>
        <div className="banner_title">Service</div>
        <div className="banner_content">
          <span className="content_span delay_06 ">
            {mobile
              ? t("serviceTranslation.mbannerExplain")
              : t("serviceTranslation.bannerExplain")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ServiceBanner;
