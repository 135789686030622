export const resources = {
  en: {
    agreeTranslation: {
      modal: {
        close: "Close",
        open: "Show",
        title: "Information on the collection and use of personal information",
        subTitle:
          "(Ltd.) collects and uses personal information as follows to process user inquiries and is committed to safely handling your personal information.",
        collections: "Collections",
        purpose: "Purpose",
        period: "period",
        email: "Email address, mobile phone number",
        inquiries:
          "Check inquiries, requests, and complaints and reply with the outcome of the process",
        retain: "Retain for 3 years and destroy without delay",
        information:
          "You have the right to refuse this consent, which will limit our ability to process your inquiry and respond to you. Please be careful not to enter any personal information that is not requested.",
      },
    },
  },
  ko: {
    agreeTranslation: {
      modal: {
        close: "전문닫기",
        open: "전문보기",
        title: "개인정보 수집·이용에 대한 안내",
        subTitle:
          "(주)리턴플러스는 이용자 문의를 처리하기 위해 다음과 같이 개인정보를 수집 및 이용하며, 이용자의 개인정보를 안전하게 취급하는데 최선을 다하고 있습니다.",
        collections: "수집항목",
        purpose: "수집목적",
        period: "보유기간",
        email: "이메일 주소, 휴대폰 번호",
        inquiries: "문의·요청·불편사항 확인 및 처리결과 회신",
        retain: "3년간 보관 후 지체없이 파기",
        information:
          "위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 문의처리 및 결과 회신이 제한됩니다. 요구하지 않은 개인정보는 입력하지 않도록 주의해주세요.",
      },
    },
  },
};
