import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomSelect from "../CompanyAndContact/CustomSelect";
import { irDownloadAlert } from "../../hooks/irDownloadAlert";
import useWindowWidth from "../../hooks/useWindowWidth";

function IRDownload() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    emailId: "",
    emailDomain: "", // Combined domain field
    name: "",
    industry: "",
    phone: "",
    agree1: false,
  });
  const [mobile, setMobile] = useState(false);
  const viewWidth = useWindowWidth(0);
  const [isCustomInput, setIsCustomInput] = useState(false); // Existing state
  const [showPrivacyModal, setShowPrivacyModal] = useState(false); // New state
  const [modalAnimation, setModalAnimation] = useState(null); // 'open' | 'close' | null

  useEffect(() => {
    if (viewWidth <= 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [viewWidth]);

  const domainOptions = t("CompanyContents.IRDownload.Form.domainSelectBox", {
    returnObjects: true,
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleEmailDomainChange = (value) => {
    if (value === "직접입력" || value === "Enter Email Domain") {
      setIsCustomInput(true);
      setFormData((prevData) => ({
        ...prevData,
        emailDomain: "", // Clear emailDomain to allow custom input
      }));
    } else {
      setIsCustomInput(false);
      setFormData((prevData) => ({
        ...prevData,
        emailDomain: value, // Set to the selected option
      }));
    }
  };

  const handleAgreeClick = (e) => {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      agree1: !prev.agree1,
    }));
  };

  // Refactored submission logic
  const submitForm = async () => {
    // 유효성 검사 로직
    if (!formData.name) {
      alert(t("CompanyContents.IRDownload.Form.Alert.name"));
      return;
    } else if (!formData.industry) {
      alert(t("CompanyContents.IRDownload.Form.Alert.industry"));
      return;
    } else if (!formData.emailId) {
      alert(t("CompanyContents.IRDownload.Form.Alert.emailId"));
      return;
    } else if (!formData.emailDomain) {
      alert(t("CompanyContents.IRDownload.Form.Alert.emailDomain"));
      return;
    } else if (!formData.phone) {
      alert(t("CompanyContents.IRDownload.Form.Alert.phone"));
      return;
    } else if (!formData.agree1) {
      alert(t("CompanyContents.IRDownload.Form.Alert.agree1"));
      return;
    }

    let today = new Date();

    let year = today.getFullYear();
    let month = ("0" + (today.getMonth() + 1)).slice(-2);
    let day = ("0" + today.getDate()).slice(-2);

    let dateString = year + "-" + month + "-" + day;
    let hours = ("0" + today.getHours()).slice(-2);
    let minutes = ("0" + today.getMinutes()).slice(-2);
    let seconds = ("0" + today.getSeconds()).slice(-2);
    let timeString = hours + ":" + minutes + ":" + seconds;

    const formDataToSubmit = {
      다운로드시간: dateString + " " + timeString,
      이메일: `${formData.emailId}@${formData.emailDomain}`,
      이름: formData.name,
      산업군: formData.industry,
      휴대폰번호: formData.phone,
      개인정보수집동의: formData.agree1,
    };
    const dataToSubmit = new FormData();
    for (const key in formDataToSubmit) {
      dataToSubmit.append(key, formDataToSubmit[key]);
    }
    try {
      await fetch(process.env.REACT_APP_COMPANY_APPSCRIPTS_URL, {
        method: "POST",
        body: dataToSubmit,
      });
      irDownloadAlert(
        t("CompanyContents.IRDownload.Form.Alert.SuccessResultMessage")
      );
    } catch {
      irDownloadAlert(
        t("CompanyContents.IRDownload.Form.Alert.FailResultMessage")
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  // Function to open the modal with animation
  const openModal = () => {
    setShowPrivacyModal(true);
    setModalAnimation("open");
  };

  // Function to close the modal with animation
  const closeModal = () => {
    setShowPrivacyModal(false);
    setModalAnimation("close");
  };

  // Handle animation end to remove modal from DOM
  const handleAnimationEnd = () => {
    if (modalAnimation === "close") {
      setShowPrivacyModal(false);
      setModalAnimation(null);
    }
  };

  // Handle overlay click to close modal
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <div className="irdownload_form_wrapper">
      <div className="irdownload_inner">
        <form className="irdownload_form_box" onSubmit={handleSubmit}>
          <div className="irdownload_title_box">
            <span className="irdownload_title" id="irdown">
              IR Download
            </span>
            <span className="irdownload_sub_title">
              {t("CompanyContents.IRDownload.SubTitle")}
            </span>
          </div>

          {/* 이름 입력 */}
          <div className="irdownload_form_inner">
            <div className="irdownload_label_box irdownload_align_center">
              <label className="irdownload_label">
                {t("CompanyContents.IRDownload.Form.name")}
                <span className="irdownload_label_span"> *</span>
              </label>
            </div>
            <div className="irdownload_input_box irdownload_with_border">
              <input
                type="text"
                name="name"
                onChange={handleChange}
                required
                placeholder={t(
                  "CompanyContents.IRDownload.Form.placeholder.input"
                )}
                className="irdownload_input_field"
              />
            </div>
          </div>

          {/* 산업군 선택 */}
          <div className="irdownload_form_inner">
            <div className="irdownload_label_box irdownload_align_center">
              <label className="irdownload_label">
                {t("CompanyContents.IRDownload.Form.industry")}
                <span className="irdownload_label_span"> *</span>
              </label>
            </div>
            <div className="irdownload_input_box">
              <CustomSelect
                options={t(
                  "CompanyContents.IRDownload.Form.industrySelectBox",
                  {
                    returnObjects: true,
                  }
                )}
                onChange={(value) =>
                  setFormData({ ...formData, industry: value })
                }
                required
              />
            </div>
          </div>

          {/* 이메일 입력 */}
          <div className="irdownload_form_inner">
            <div className="irdownload_label_box irdownload_align_center">
              <label className="irdownload_label">
                {t("CompanyContents.IRDownload.Form.email")}
                <span className="irdownload_label_span"> *</span>
              </label>
            </div>
            <div className="irdownload_email_input_box">
              <div className="email_input_container">
                <input
                  type="text"
                  name="emailId"
                  placeholder={t(
                    "CompanyContents.IRDownload.Form.placeholder.input"
                  )}
                  onChange={handleChange}
                  required
                  className="irdownload_email_input"
                />
                <span className="irdownload_email_span">@</span>
                {mobile ? (
                  <CustomSelect
                    options={domainOptions}
                    onChange={handleEmailDomainChange}
                    required
                    allowCustomInput={true} // Enable custom input on mobile
                  />
                ) : (
                  <div className="irdownload_email_input_inner">
                    <input
                      type="text"
                      name="emailDomain"
                      placeholder={
                        isCustomInput
                          ? t(
                              "CompanyContents.IRDownload.Form.placeholder.input"
                            )
                          : t(
                              "CompanyContents.IRDownload.Form.placeholder.category"
                            )
                      }
                      value={formData.emailDomain}
                      disabled={!isCustomInput}
                      onChange={handleChange}
                      required
                      className="irdownload_email_input_current"
                    />
                    <div className="irdownload_custom_select_box">
                      <CustomSelect
                        options={domainOptions}
                        onChange={handleEmailDomainChange}
                        required
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* 휴대폰 번호 입력 */}
          <div className="irdownload_form_inner">
            <div className="irdownload_label_box irdownload_align_center">
              <label className="irdownload_label">
                {t("CompanyContents.IRDownload.Form.phone")}
                <span className="irdownload_label_span"> *</span>
              </label>
            </div>
            <div className="irdownload_input_box irdownload_with_border">
              <input
                type="text"
                placeholder={t(
                  "CompanyContents.IRDownload.Form.placeholder.phone"
                )}
                name="phone"
                onChange={handleChange}
                maxLength="12"
                minLength="9"
                required
                className="irdownload_input_field"
              />
            </div>
          </div>

          {/* 개인정보 수집 동의 */}
          <div className="irdownload_checkbox_wrapper">
            <div className="irdownload_checkbox_box">
              <input
                type="checkbox"
                name="agree1"
                onChange={handleChange}
                checked={formData.agree1}
                className="irdownload_checkbox_input"
                id="irdownload_agree1"
              />
              <div
                className={`irdownload_custom_checkbox ${
                  formData.agree1 ? "irdownload_checked" : ""
                }`}
                onClick={handleAgreeClick}
              ></div>
              <label
                htmlFor="irdownload_agree1"
                className="irdownload_checkbox_label"
                onClick={handleAgreeClick}
              >
                {t("CompanyContents.IRDownload.Form.privacyPolicy.Title")}
              </label>
              {mobile && (
                <button
                  type="button"
                  className="irdownload_view_details_button"
                  onClick={openModal}
                >
                  {t("agreeTranslation.modal.open")}
                </button>
              )}
            </div>
            {mobile && showPrivacyModal && (
              <div
                className={`modal_overlay ${
                  modalAnimation === "open"
                    ? "modal_open"
                    : modalAnimation === "close"
                    ? "modal_close"
                    : ""
                }`}
                onClick={handleOverlayClick}
                onAnimationEnd={handleAnimationEnd}
              >
                <div
                  className="modal_content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal_irdownload_checkbox_box">
                    <input
                      type="checkbox"
                      name="agree1"
                      onChange={handleChange}
                      checked={formData.agree1}
                      className="modal_irdownload_checkbox_input"
                      id="modal_irdownload_agree1"
                    />
                    <div
                      className={`modal_irdownload_custom_checkbox ${
                        formData.agree1 ? "modal_irdownload_checked" : ""
                      }`}
                      onClick={handleAgreeClick}
                    ></div>
                    <label
                      htmlFor="modal_irdownload_agree1"
                      className="modal_irdownload_checkbox_label"
                      onClick={handleAgreeClick}
                    >
                      {t("CompanyContents.IRDownload.Form.privacyPolicy.Title")}
                    </label>
                    <button
                      type="button"
                      className="modal_irdownload_view_details_button"
                      onClick={closeModal}
                    >
                      {t("agreeTranslation.modal.close")}
                    </button>
                  </div>
                  <p className="irdownload_privacy_policy_content irdownload_subtitle">
                    {t("agreeTranslation.modal.title")}
                  </p>
                  <p className="irdownload_privacy_policy_content irdownload_content_title">
                    {t("agreeTranslation.modal.subTitle")}
                  </p>
                  <div className="irdownload_privacy_policy_grid">
                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_term">
                      {t("agreeTranslation.modal.collections")}
                    </div>
                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_term">
                      {t("agreeTranslation.modal.purpose")}
                    </div>
                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_term">
                      {t("agreeTranslation.modal.period")}
                    </div>

                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_item_content">
                      {t("agreeTranslation.modal.email")}
                    </div>
                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_purpose_content">
                      {t("agreeTranslation.modal.inquiries")}
                    </div>
                    <div className="irdownload_privacy_policy_grid_item irdownload_collect_term_content">
                      {t("agreeTranslation.modal.retain")}
                    </div>
                  </div>
                  <p className="irdownload_privacy_policy_content irdownload_warning">
                    {t("agreeTranslation.modal.information")}
                  </p>
                  <div className="irdownload_button_box">
                    <button type="submit" className="irdownload_submit_button">
                      {t("CompanyContents.IRDownload.Form.Button")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {!mobile && (
              <>
                <p className="irdownload_privacy_policy_content irdownload_subtitle">
                  {t("agreeTranslation.modal.title")}
                </p>
                <p className="irdownload_privacy_policy_content irdownload_content_title">
                  {t("agreeTranslation.modal.subTitle")}
                </p>
                <div className="irdownload_privacy_policy_grid">
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_item">
                    {t("agreeTranslation.modal.collections")}
                  </div>
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_purpose">
                    {t("agreeTranslation.modal.purpose")}
                  </div>
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_term">
                    {t("agreeTranslation.modal.period")}
                  </div>
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_item_content">
                    {t("agreeTranslation.modal.email")}
                  </div>
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_purpose_content">
                    {t("agreeTranslation.modal.inquiries")}
                  </div>
                  <div className="irdownload_privacy_policy_grid_item irdownload_collect_term_content">
                    {t("agreeTranslation.modal.retain")}
                  </div>
                </div>
                <p className="irdownload_privacy_policy_content irdownload_warning">
                  {t("agreeTranslation.modal.information")}
                </p>
              </>
            )}
          </div>

          <div className="irdownload_button_box">
            <button type="submit" className="irdownload_submit_button">
              {t("CompanyContents.IRDownload.Form.Button")}
            </button>
          </div>
        </form>
      </div>

      {/* 모달 (비모바일용) */}
      {/* 비모바일에서도 모달을 원하신다면 여기에 추가할 수 있습니다 */}
    </div>
  );
}

export default IRDownload;
