import { useTranslation } from "react-i18next";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useEffect, useState } from "react";

function CompanyBanner() {
  const { t } = useTranslation();

  const [mobile, setMobile] = useState(false);
  const viewWidth = useWindowWidth(0);

  useEffect(() => {
    if (viewWidth <= 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [viewWidth]);

  return (
    <div className="banner_wrapper">
      <img
        src={
          mobile
            ? "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/8b006d5b-86f6-40a7-4e07-7d52ffa06900/companybanner"
            : "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/18b7dd18-8be7-4383-7074-8d69f6035b00/banner"
        }
        alt="Banner"
        className={mobile ? "mobile_banner_img" : "banner_img"}
        loading="lazy"
        decoding="async"
      />
      <div className="banner_content_box">
        <p className="nav_content">Home · Company</p>
        <p className="banner_title">Return Plus</p>
        <p className="banner_content">
          <span className="content_span delay_06">
            {t("CompanyBanner.FirstLine")}
          </span>
          <span className="content_span delay_08">
            {t("CompanyBanner.SecondLine")}
          </span>
        </p>
      </div>
    </div>
  );
}

export default CompanyBanner;
