// Header.jsx
import { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as TransSvg } from "../../assets/images/헤더_지구본_화살표.svg";
import { ReactComponent as TransLangSvg } from "../../assets/images/language.svg";
import { ReactComponent as CheckSvg } from "../../assets/images/헤더_언어변경박스_체크.svg";
import logo from "../../assets/images/헤더_로고.avif";
import { useTranslation } from "react-i18next";
import useWindowWidth from "../../hooks/useWindowWidth";

function Header() {
  const [burger, setBurger] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const viewWidth = useWindowWidth(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const modalRef = useRef(null);
  const translationRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const lastScrollY = useRef(0);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (viewWidth <= 1024) {
      setBurger(true);
      setIsMenuOpen(false);
    } else {
      setBurger(false);
      setIsMenuOpen(false);
    }
  }, [viewWidth]);

  const handleLanguageSelect = (lang) => {
    lastScrollY.current = window.scrollY;
    i18n.changeLanguage(lang);
    setIsModalOpen(false);
    setIsVisible(true);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenuOpen]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        translationRef.current &&
        !translationRef.current.contains(event.target)
      ) {
        setIsModalOpen(false);
      }
    }

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  useEffect(() => {
    let timeout = null;
    const handleScroll = () => {
      // Ignore scroll events if menu or modal is open
      if (isMenuOpen || isModalOpen) return;

      if (timeout !== null) {
        window.cancelAnimationFrame(timeout);
      }

      timeout = window.requestAnimationFrame(() => {
        const currentScrollY = window.scrollY;

        // 최소 스크롤 거리 설정 (너무 민감하게 반응하지 않도록)
        const scrollDelta = currentScrollY - lastScrollY.current;

        if (Math.abs(scrollDelta) < 10) {
          return;
        }

        if (currentScrollY > lastScrollY.current) {
          // 아래로 스크롤
          if (currentScrollY > 100) {
            // 약간의 스크롤 여유 추가
            setIsVisible(false);
          }
        } else {
          // 위로 스크롤
          setIsVisible(true);
        }

        lastScrollY.current = currentScrollY;
      });
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }
    };
  }, [isMenuOpen, isModalOpen]); // Added isModalOpen to dependencies

  const headerClassName = `header_wrapper ${isVisible ? "visible" : "hidden"}`;

  return (
    <header className={headerClassName}>
      <div className="header_inner">
        <NavLink to="/" className="logo_box" onClick={closeMenu}>
          <img
            src={logo}
            alt="로고"
            className="logo_img"
            loading="lazy"
            decoding="async"
          />
        </NavLink>
        {burger ? (
          <div className="burger_box">
            <button
              className={`burger_menu ${isMenuOpen ? "burger_open" : ""}`}
              onClick={toggleMenu}
            >
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </button>
            {isMenuOpen && (
              <button
                className="translation_button"
                onClick={() => setIsModalOpen(!isModalOpen)}
                ref={translationRef}
              >
                <TransLangSvg className="trans_lang_svg" />
              </button>
            )}
          </div>
        ) : (
          <div className="nav_tool_box">
            <div className={`nav_box`} id="navigation">
              <NavLink to="/company" className="nav_item" onClick={closeMenu}>
                Company
              </NavLink>
              <NavLink to="/service" className="nav_item" onClick={closeMenu}>
                Service
              </NavLink>
              <NavLink to="/news" className="nav_item" onClick={closeMenu}>
                News
              </NavLink>
              <NavLink to="/contact" className="nav_item" onClick={closeMenu}>
                Contact Us
              </NavLink>
            </div>
            <div className="tool_box">
              <NavLink
                to="/contact/#contactform"
                className="inquiry"
                onClick={closeMenu}
              >
                {t("mainTranslation.contactUsBtn")}
              </NavLink>
              <span className="separator">|</span>
              <button
                className="translation_button"
                onClick={() => setIsModalOpen(!isModalOpen)}
                ref={translationRef}
              >
                <TransSvg className="trans_svg" />
              </button>
            </div>
          </div>
        )}
      </div>

      {isModalOpen && (
        <>
          <div className="modal_overlay"></div>
          <div className="modal_container" ref={modalRef}>
            <button
              className="modal_option"
              onClick={() => handleLanguageSelect("ko")}
            >
              <div className="icon_wrapper">
                {i18n.language === "ko" && <CheckSvg width={13} />}
              </div>
              <span>KO</span>
            </button>
            <button
              className="modal_option"
              onClick={() => handleLanguageSelect("en")}
            >
              <div className="icon_wrapper">
                {i18n.language === "en" && <CheckSvg width={13} />}
              </div>
              <span>EN</span>
            </button>
          </div>
        </>
      )}

      {burger && (
        <div className={`burger_menu_list_box ${isMenuOpen && "active"}`}>
          <ul className="burger_menu_list">
            <li className="burger_menu_item">
              <Link to="/company" onClick={closeMenu}>
                Company
              </Link>
            </li>
            <li className="burger_menu_item">
              <Link to="/service" onClick={closeMenu}>
                Service
              </Link>
            </li>
            <li className="burger_menu_item">
              <Link to="/news" onClick={closeMenu}>
                News
              </Link>
            </li>
            <li className="burger_menu_item">
              <Link to="/contact" onClick={closeMenu}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
}

export default Header;
