import { useTranslation } from "react-i18next";
import block from "../../assets/images/서비스_솔루션_블록체인.avif";
import auto from "../../assets/images/서비스_솔루션_자동화.avif";
import ai from "../../assets/images/서비스_솔루션_AI.avif";
import { useEffect, useState } from "react";
import useWindowWidth from "../../hooks/useWindowWidth";

function SolutionDetail() {
  const { t } = useTranslation();
  const [mobile, setMobile] = useState(false);
  const viewWidth = useWindowWidth(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (viewWidth < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
      setCurrentSlide(0);
    }
  }, [viewWidth]);

  const imageUrls = [block, auto, ai];

  const mimageUrls = [
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/b5696060-6a57-475d-7a17-1c3864277100/mservice",
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/7c321794-1522-4d04-888b-37ea99161400/mservice",
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/a3b7c3b7-bbd1-4c18-a701-a3e8062bbd00/mservice",
  ];

  const currentImageUrls = mobile ? mimageUrls : imageUrls;
  const totalSlides = currentImageUrls.length;

  // 이전 슬라이드로 이동
  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));
  };

  // 다음 슬라이드로 이동
  const handleNext = () => {
    setCurrentSlide((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
  };

  // 터치 스와이프 핸들링
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const minSwipeDistance = 50; // 스와이프 민감도 조절

    if (distance > minSwipeDistance) {
      // 왼쪽으로 스와이프
      handleNext();
    } else if (distance < -minSwipeDistance) {
      // 오른쪽으로 스와이프
      handlePrev();
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  return (
    <div className="solution_detail_wrapper">
      <div className="solution_container">
        <div className="solution_detail_title">Solution</div>
        <div className="solution_detail_text" id="s-solution">
          {t("serviceTranslation.solutionDetail.solutionPageSubTitle")}
        </div>
        {mobile ? (
          // 모바일 뷰: 슬라이드(Carousel) 렌더링
          <div
            className="solution_carousel"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div
              className="carousel_inner"
              style={{
                transform: `translateX(-${currentSlide * 100}%)`,
                transition: "transform 0.5s ease-in-out",
              }}
            >
              {currentImageUrls.map((url, index) => (
                <div className="solution_list_container" key={index}>
                  <div className="solution_content_box">
                    <div className="solution_image_box">
                      <img
                        src={url}
                        alt={`solution_image_${index + 1}`}
                        className="solution_image"
                      />
                    </div>
                    <div className="solution_item">
                      <div className="item_title_box">
                        <div className="solution_item_title">
                          {t(
                            `serviceTranslation.solutionDetail.solution${
                              index + 1
                            }.solutionTitle`
                          )}
                        </div>
                        <div className="sub_title">
                          {t(
                            `serviceTranslation.solutionDetail.solution${
                              index + 1
                            }.solutionSubTitle`
                          )}
                        </div>
                      </div>
                      <div className="text_box">
                        {t(
                          `serviceTranslation.solutionDetail.solution${
                            index + 1
                          }.solutionContent`
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* 네비게이션 버튼 */}
            <button
              className="carousel_button prev"
              onClick={handlePrev}
              aria-label="Previous Slide"
            >
              &#10094;
            </button>
            <button
              className="carousel_button next"
              onClick={handleNext}
              aria-label="Next Slide"
            >
              &#10095;
            </button>
          </div>
        ) : (
          // 데스크탑 뷰: 모든 이미지 한 번에 렌더링
          currentImageUrls.map((url, index) => (
            <div className="solution_list_container" key={index}>
              <div className="solution_content_box">
                <div className="solution_image_box">
                  <img
                    src={url}
                    alt={`solution_image_${index + 1}`}
                    className="solution_image"
                  />
                </div>
                <div className="solution_item">
                  <div className="item_title_box">
                    <div className="solution_item_title">
                      {t(
                        `serviceTranslation.solutionDetail.solution${
                          index + 1
                        }.solutionTitle`
                      )}
                    </div>
                    {[
                      "Distributed Ledger Technology",
                      "Automatically",
                    ].includes(
                      t(
                        `serviceTranslation.solutionDetail.solution${
                          index + 1
                        }.solutionTitle`
                      )
                    ) && (
                      <div className="sub_title">
                        {t(
                          `serviceTranslation.solutionDetail.solution${
                            index + 1
                          }.solutionSubTitle`
                        )}
                      </div>
                    )}
                  </div>
                  <div className="text_box">
                    {t(
                      `serviceTranslation.solutionDetail.solution${
                        index + 1
                      }.solutionContent`
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default SolutionDetail;
