export const resources = {
  en: {
    newsTranslation: {
      goToArticle: "Go to Article",
      allBtn: "All",
      newBtn: "#News",
      tidingsBtn: "#Updates",
      creationDate: "Creation date:",
      goToNewsBtn: "Shortcut to main News",
      inquiryIR: "IR data inquiry",
      goNewsPage: "Go to news page",
      noData: "no Data",
    },
  },
  ko: {
    newsTranslation: {
      goToArticle: "기사 보러 가기",
      allBtn: "전체",
      newBtn: "#뉴스",
      tidingsBtn: "#소식",
      creationDate: "작성일자:",
      goToNewsBtn: "본문 바로 가기",
      inquiryIR: "IR 자료 문의",
      goNewsPage: "뉴스 및 소식 바로가기",
      noData: "아직 소식이 없습니다",
    },
  },
};
