export const resources = {
  en: {
    mainTranslation: {
      banner: {
        text1: "ReturnPlus leverages blockchain technology to",
        text2:
          "specialize in issuing and distributing tokenized securities of physical assets.",
        text3:
          "Through the 'Hanjogak' platform, we tokenize various assets such as ",
        text4: "real estate, medical devices, artworks, and hypercars, ",
        text5:
          "allowing anyone to easily invest by tokenizing these assets, providing new investment opportunities.",
        irDownload: "Download IR",
        text1m: "ReturnPlus leverages ",
        text2m: "blockchain technology to specialize in issuing ",
        text3m: "and distributing tokenized securities of physical assets. ",
        text4m:
          "Through the 'Hanjogak' platform, we tokenize various assets such as real estate,  ",
        text5m:
          "medical devices, artworks, and hypercars, allowing anyone to easily invest by tokenizing these assets, ",
        text6m: "providing new investment opportunities.",
      },
      solution: {
        wallet: "Wallet for Institutions and Businesses",
        platform: "STO Platform for Tokenizing and Issuing Various Assets",
        trading: "Investment Trading Platform",
      },
      platform: {
        hanjogak: {
          title1: "Hanjogak",
          description1:
            "'Hanjogak' is a blockchain-based STO (Security Token Offering) platform that digitizes high-value assets such as medical devices, hypercars, artworks, and real estate, providing distributed investment opportunities to small investors.",
          title2: "Key Features",
          list1:
            "Application of blockchain technology to ensure asset digitization and security",
          list2:
            "Digitization of high-value assets to provide small investment opportunities",
          list3:
            "Ensuring transparency of asset ownership and transaction history through blockchain",
          list4:
            "Supporting structures that allow diversified investment in various assets",
        },
        wallet: {
          title1: "Wallet Service",
          description1:
            "The Wallet Service is a safe and convenient digital asset management solution designed for institutions and businesses. It enhances security through advanced encryption and multi-factor authentication, supports efficient operations with an intuitive interface, diverse currency support, real-time transaction monitoring, and integrated reporting features.",
          title2: "Key Features",
          list1: "User-friendly interface",
          list2: "Robust security features",
          list3: "Multi-currency support",
          list4: "Real-time transaction history monitoring",
        },
        trading: {
          title1: "Trading Platform",
          description1:
            "The Trading Platform provides an efficient trading environment for investors. It enables swift and accurate investments through high-speed transaction processing and a variety of analytical tools.",
          title2: "Key Features",
          list1: "Fast transaction processing",
          list2: "Provision of various investment tools",
          list3: "Real-time market data",
          list4: "User-customizable portfolio",
        },
      },
      app: {
        title: "A New Financial Experience at Your Fingertips",
        content:
          "With the ReturnPlus app,\neasily monitor your assets and experience fast and efficient trading!",
      },
      news: {
        goNewsBtn: "Go to News and Updates",
      },
      copyRight: {
        content: "Faster, Smarter Asset Management",
        contentm: "Faster,\nSmarter Asset Management",
      },
      contact: {
        content1: "Together with ReturnPlus",
        content2: "From the start of finance to the future",
        content3: "Join ReturnPlus now",
      },
      contactUsBtn: "Contact",
    },
  },
  ko: {
    mainTranslation: {
      banner: {
        text1: "리턴플러스는 블록체인 기술을 활용하여",
        text2:
          "실물 자산의 토큰 증권 발행과 유통을 전문으로 하는 핀테크 기업입니다.",
        text3: "'한 조각' 플랫폼을 통해 부동산, 의료기기, 예술품, 하이퍼카 등",
        text4: "다양한 자산을 누구나 쉽게 투자할 수 있도록 토큰화하여",
        text5: "새로운 투자 기회를 제공합니다.",
        irDownload: "IR 다운로드",
        text1m: "리턴플러스는 블록체인 기술을 활용하여",
        text2m: "실물 자산의 토큰 증권 발행과 유통을 전문으로 하는",
        text3m: "핀테크 기업입니다.",
        text4m: "'한 조각' 플랫폼을 통해 부동산, 의료기기, 예술품, 하이퍼카",
        text5m: "등 다양한 자산을 누구나 쉽게 투자할 수 있도록 토큰화하여",
        text6m: "새로운 투자 기회를 제공합니다.",
      },
      solution: {
        wallet: "기관, 기업을 위한 월렛",
        platform: "다양한 자산 토큰화 및 발행/유통하는 STO 플랫폼",
        trading: "투자 트레이딩 플랫폼",
      },
      platform: {
        hanjogak: {
          title1: "한조각",
          description1:
            "‘한 조각’은 블록체인 기반의 STO(Security Token Offering) 플랫폼으로 의료기기, 하이퍼카, 미술품, 부동산 등 고가 자산을 디지털화하여 소액 투자자들에게 분산된 투자 기회를 제공합니다.",
          title2: "특장점",
          list1: " 자산의 디지털화 및 보안성을 보장하는 블록체인 기술 적용",
          list2: " 고가 자산을 디지털화하여 소액 투자 기회 제공",
          list3: " 블록체인을 통해 자산 소유권과 거래 내역의 투명성 확보",
          list4: " 다양한 자산에 분산 투자할 수 있는 구조 지원",
        },
        wallet: {
          title1: "월렛 서비스",
          description1:
            "월렛 서비스는 기관과 기업을 위한 안전하고 편리한 디지털 자산 관리 솔루션입니다. 고급 암호화와 다중 인증을 통해 보안을 강화하고, 직관적인 인터페이스와 다양한 통화 지원, 실시간 거래 모니터링 및 통합 보고서 기능으로 효율적인 운영을 지원합니다.",
          title2: "특장점",
          list1: " 사용이 간편한 인터페이스",
          list2: " 강력한 보안 기능",
          list3: " 다중 통화 지원",
          list4: " 실시간 거래 내역 확인",
        },
        trading: {
          title1: "트레이딩 플랫폼",
          description1:
            "트레이딩 플랫폼은 투자자들에게 효율적인 거래 환경을 제공합니다. 고속 거래 처리와 다양한 분석 도구를 통해 사용자가 신속하고 정확한 투자를 가능하게 합니다.",
          title2: "특장점",
          list1: " 빠른 거래 처리",
          list2: " 다양한 투자 도구 제공",
          list3: " 실시간 시장 데이터",
          list4: " 사용자 맞춤형 포트폴리오",
        },
      },
      app: {
        title: "손끝에서 시작되는 새로운 금융 경험",
        content:
          "리턴플러스 앱으로\n자산을 쉽게 확인하고 빠르고 효율적인 거래를 만나보세요!",
      },
      news: {
        goNewsBtn: "뉴스 및 소식 바로가기",
      },
      copyRight: {
        content: "더 빠르고 스마트한 자산 관리의 시작",
        contentm: "더 빠르고\n스마트한 자산 관리의 시작",
      },
      contact: {
        content1: "리턴플러스와 함께",
        content2: "금융의 시작부터 미래까지",
        content3: "이제 리턴플러스와 함께 하세요",
      },
      contactUsBtn: "문의하기",
    },
  },
};
