export const resources = {
  en: {
    footerTranslation: {
      companyName: "ReturnPlus Co., Ltd.",
      representative: "Representative: Choi Jeong-man",
      headOffice1:
        "Head Office: Room 414, 144 Gasan Digital 2-ro, Geumcheon-gu, Seoul",
      headOffice2:
        "R&D center: 40, Seolleung-ro 86-gil, Gangnam-gu, Seoul, 4th Floor",
      otherInformation:
        "Email: contact@returnplus.kr | Business Registration Number: 368-87-03414",
      inquiryBtn: "Request IR Materials",
      employmentBtn: "Careers",
      copyright: "Copyright © 2024 ReturnPlus Co., Ltd. All rights reserved.",
      siteMap: "Sitemap",
      familySite: "Family Site",
      mobileInfo1: "Email: contact@returnplus.kr",
      mobileInfo2: "Business Registration Number: 368-87-03414",
    },
  },
  ko: {
    footerTranslation: {
      companyName: "(주)리턴플러스",
      representative: "대표 : 최정만",
      headOffice1: "본사 : 서울특별시 금천구 가산디지털2로 144, 414호",
      headOffice2: "연구센터 : 서울특별시 강남구 선릉로86길 40 4층",
      otherInformation:
        "이메일 : contact@returnplus.kr | 사업자 등록번호 : 368-87-03414",
      inquiryBtn: "IR 자료 문의",
      employmentBtn: "인재채용",
      copyright: "Copyright © 2024 (주)리턴플러스 All rights reserved.",
      siteMap: "사이트맵",
      familySite: "패밀리 사이트",
      mobileInfo1: "이메일 : contact@returnplus.kr",
      mobileInfo2: "사업자 등록번호 : 368-87-03414",
    },
  },
};
