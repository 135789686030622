// src/components/CustomSelect/CustomSelect.jsx
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useWindowWidth from "../../hooks/useWindowWidth";

const CustomSelect = ({
  label,
  options,
  onChange,
  required,
  allowCustomInput = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [customInput, setCustomInput] = useState("");
  const [mobile, setMobile] = useState(false);
  const viewWidth = useWindowWidth(0);
  const inputRef = useRef(null);

  // 화면 너비가 바뀌면 mobile 여부 확인
  useEffect(() => {
    if (viewWidth <= 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [viewWidth]);

  // 셀렉트 영역 밖 클릭하면 닫히도록
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".custom_select_container")) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleOptions = () => setIsOpen(!isOpen);

  // “직접입력”이 마지막 옵션이라고 가정
  const isDirectInputSelected =
    allowCustomInput && selectedOption === options[options.length - 1];

  const handleSelectBoxClick = () => {
    toggleOptions();
    if (isDirectInputSelected && inputRef.current) {
      inputRef.current.focus();
    }
  };

  // 옵션 클릭 시 반드시 onChange를 호출
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onChange) {
      onChange(option); // 부모로 선택값 전달
    }
  };

  // Custom Input 변경 시 (모바일에서 직접입력 시에만 쓰임)
  const handleCustomInputChange = (e) => {
    const value = e.target.value;
    setCustomInput(value);
  };

  // 직접입력을 선택한 경우 input에 포커스
  useEffect(() => {
    if (isDirectInputSelected && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isDirectInputSelected]);

  // 모바일에서 직접입력 시 변경될 때마다 부모의 onChange로 전달
  useEffect(() => {
    if (isDirectInputSelected) {
      onChange?.(customInput);
    }
  }, [isDirectInputSelected, customInput, onChange]);

  return (
    <div className="custom_select_container">
      {label && (
        <label>
          {label} {required && "*"}
        </label>
      )}
      {/* 선택 영역 */}
      <div className="custom_select_box" onClick={handleSelectBoxClick}>
        <div className="selected_option">
          {isDirectInputSelected
            ? ""
            : selectedOption ||
              t("CompanyContents.IRDownload.Form.placeholder.category")}
        </div>
        <div className={`arrow ${isOpen ? "up" : "down"}`}></div>
      </div>

      {/* 옵션 리스트 (PC에서는 dropdown, 모바일에서는 modal) */}
      {isOpen &&
        (mobile ? (
          <div className="custom_overlay">
            <div className="custom_option_modal">
              <div className="custom_option_modal_list">
                {options.map((option, index) => (
                  <div
                    key={index}
                    className="custom_option_modal_item"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="options_list">
            {options.map((option, index) => (
              <div
                key={index}
                className="option_item"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))}
          </div>
        ))}

      {isDirectInputSelected && (
        <input
          ref={inputRef}
          className="custom_input_field"
          type="text"
          value={customInput}
          onChange={handleCustomInputChange}
          placeholder="직접입력"
        />
      )}
    </div>
  );
};

export default CustomSelect;