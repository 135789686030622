// CompanyContent.jsx

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import visionImg from "../../assets/images/컴퍼니_비전.avif";
import { companySvgList } from "../../util/data/Company/companySvgList";
import { companyHistoryData } from "../../util/data/Company/companyHistoryData";
import IRDownload from "../../components/CompanyAndContact/IRDownload";
import useHashScroll from "../../hooks/useHashScroll";
import useWindowWidth from "../../hooks/useWindowWidth";

function CompanyContent() {
  const { t } = useTranslation();
  useHashScroll();
  const [selectedYear, setSelectedYear] = useState("2026");
  const [mobile, setMobile] = useState(false);
  const viewWidth = useWindowWidth(0);

  const [showAllYears, setShowAllYears] = useState(false);

  const handleYearClick = (year) => {
    setSelectedYear(year);
  };

  const handleToggleShow = () => {
    setShowAllYears((prevShowAll) => !prevShowAll);
  };

  useEffect(() => {
    if (viewWidth <= 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [viewWidth]);

  const sortedYears = Object.keys(companyHistoryData).sort((a, b) => b - a);

  const visibleYears = showAllYears ? sortedYears : sortedYears.slice(0, 1);

  return (
    <div className="company_wrapper">
      <section className="mission_section" id="vision">
        <div className="section_inner">
          <div className="mission_part">
            <span className="mission_title">RETURN PLUS</span>
            <span className="mission_heading">
              {t("CompanyContents.CoreValues.Mission.Title")}
            </span>
          </div>
          <div className="mission_part">
            <span className="mission_content">
              {t("CompanyContents.CoreValues.Mission.ContentFirst")}
            </span>
            <span className="mission_content">
              <span className="bold">
                {t("CompanyContents.CoreValues.Mission.ContentAccent")}{" "}
              </span>
              {t("CompanyContents.CoreValues.Mission.ContentSecond")}
            </span>
          </div>
        </div>
      </section>
      <section
        className="vision_section"
        style={{ backgroundImage: `url(${visionImg})` }}
      >
        <div className="section_inner">
          <div className="vision_part">
            <span className="vision_title">RETURN PLUS</span>
            <span className="vision_heading">
              {t("CompanyContents.CoreValues.Vision.Title")}
            </span>
          </div>
          <div className="vision_part">
            <span className="vision_content">
              {t("CompanyContents.CoreValues.Vision.ContentFirst")}
            </span>
            <span className="vision_content">Leader of Investment Future</span>
          </div>
        </div>
      </section>
      <section className="slogan_section">
        <div className="section_inner">
          <div className="slogan_part">
            <span className="slogan_title">RETURN PLUS</span>
            <span className="slogan_heading">
              {t("CompanyContents.CoreValues.Slogan.Title")}
            </span>
          </div>
          <div className="slogan_part">
            <span className="slogan_content">
              <span className="bold">
                {t("CompanyContents.CoreValues.Slogan.ContentAccent")}
              </span>
              <span>{t("CompanyContents.CoreValues.Slogan.ContentFirst")}</span>
            </span>
            <div className="slogans">
              {companySvgList &&
                companySvgList.map((slogan, index) => (
                  <div key={index} className="slogan_item">
                    <span className="slogan_icon">
                      {slogan.icon("2.783rem")}
                    </span>
                    <div className="slogan_box">
                      <span className="slogan_name">{t(slogan.name)}</span>
                      <span className="slogan_description">
                        {t(slogan.descriptions)}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>

      <section className="history_section" id="history">
        <div className="section_history_inner">
          <span className="history_title">History</span>
          {mobile ? (
            <div className="history_content_box">
              <div className="history_details">
                {/* 최신 연도 항상 표시 */}
                {sortedYears.length > 0 && (
                  <React.Fragment key={sortedYears[0]}>
                    <span className="history_year_title">{sortedYears[0]}</span>
                    {companyHistoryData[sortedYears[0]].map((entry, idx) => {
                      const translatedContent = t(entry.content, {
                        returnObjects: true,
                      });
                      return (
                        <React.Fragment key={idx}>
                          <span className="history_month">{`${sortedYears[0]}.${entry.month}`}</span>
                          {translatedContent &&
                            translatedContent.map((desc, descIdx) => (
                              <span
                                key={descIdx}
                                className={`history_description ${
                                  entry.description === "Title" ? "title" : ""
                                }`}
                              >
                                {t(desc)}
                              </span>
                            ))}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                )}

                {/* 나머지 연도 슬라이드 컨테이너 */}
                <div
                  className={`history_other_years ${
                    showAllYears ? "show_all" : "collapsed"
                  }`}
                >
                  {sortedYears.slice(1).map((year) => (
                    <React.Fragment key={year}>
                      <span className="history_year_title">{year}</span>
                      {companyHistoryData[year].map((entry, idx) => {
                        const translatedContent = t(entry.content, {
                          returnObjects: true,
                        });
                        return (
                          <React.Fragment key={idx}>
                            <span className="history_month">{`${year}.${entry.month}`}</span>
                            {translatedContent &&
                              translatedContent.map((desc, descIdx) => (
                                <span
                                  key={descIdx}
                                  className={`history_description ${
                                    entry.description === "Title" ? "title" : ""
                                  }`}
                                >
                                  {t(desc)}
                                </span>
                              ))}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  ))}
                </div>

                {/* "더보기" / "접기" 버튼 */}
                {sortedYears.length > 1 && (
                  <div className="show_more_button_box">
                    <button
                      type="button" // 기본 제출 동작 방지
                      className="show_more_button"
                      onClick={handleToggleShow}
                      aria-expanded={showAllYears}
                      aria-controls="history_other_years"
                      aria-label={
                        showAllYears
                          ? t("CompanyContents.History.ShowLess")
                          : t("CompanyContents.History.ShowMore")
                      }
                    >
                      <span>
                        {showAllYears
                          ? t("CompanyContents.History.ShowLess")
                          : t("CompanyContents.History.ShowMore")}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="13"
                        viewBox="0 0 22 13"
                        fill="none"
                        className={`show_more_button_svg ${
                          showAllYears ? "show_more_button_svg_trans" : ""
                        }`}
                        aria-hidden="true"
                      >
                        <path
                          d="M2.1001 0.243441L10.7001 8.2398L19.3001 0.243442L21.4 2.19594L10.7001 12.145L2.21096e-06 2.19594L2.1001 0.243441Z"
                          fill="#242424"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            // 데스크탑 뷰 로직은 기존대로 유지
            <div className="history_content_box">
              <div className="history_header">
                <span className="history_subtitle">
                  {t("CompanyContents.History.SubTitle")}
                </span>
              </div>
              <div className="history_year_selector">
                {sortedYears.map((year) => (
                  <span
                    key={year}
                    className={`history_year ${
                      selectedYear === year ? "active" : ""
                    }`}
                    onClick={() => handleYearClick(year)}
                  >
                    {year}
                  </span>
                ))}
              </div>
              <div className="history_details">
                <span className="history_year_title">{selectedYear}</span>
                {companyHistoryData[selectedYear].map((entry, index) => {
                  const translatedContent = t(entry.content, {
                    returnObjects: true,
                  });
                  return (
                    <React.Fragment key={index}>
                      <span className="history_month">{`${selectedYear}.${entry.month}`}</span>
                      {translatedContent &&
                        translatedContent.map((desc, idx) => (
                          <span
                            key={idx}
                            className={`history_description ${
                              entry.description === "Title" ? "title" : ""
                            }`}
                          >
                            {t(desc)}
                          </span>
                        ))}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="talent1_section">
        <img
          src={`https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/28992dd8-300c-4d1d-b0c0-5ed0e97e0800/companytal`}
          alt="talent1_section"
        />
      </section>
      <section className="talent2_section" id="talent">
        <div className="talent2_section_wrapper">
          <div className="talent_image">
            <img
              src={
                "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/f214da38-315a-40fc-cf6c-b2820bd04f00/companytal"
              }
              alt="talent2_section"
            />
          </div>
        </div>
      </section>
      <IRDownload />
    </div>
  );
}

export default CompanyContent;
