// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const enServiceResources = {
  serviceTranslation: {
    // 메인화면
    bannerExplain:
      "New services that you can't experience right now are coming soon.",
    mbannerExplain:
      "New services that you can't\nexperience right now are coming soon.",
    content: {
      contentExplan:
        "We provide flexible and customized solutions and services with advanced technology and expertise.",
      mcontentExplan:
        "We provide flexible and customized solutions\nand services with advanced technology and expertise.",
      solutionDetailTitle: "Detailed solution",
      serviceDetailTitle: "Detailed service",
    },
    // 솔루션상세
    solutionDetail: {
      solutionPageSubTitle:
        "Solutions specifically tailored and optimized for you",
      solution1: {
        solutionTitle: "Distributed Ledger Technology",
        solutionSubTitle: "Distributed Ledger Technology",
        solutionContent: `Based on DLT (Distributed Ledger Technology), transactions and data are distributed and stored in an encrypted manner to maximize security. Smart Contracts enable programmable finance and provide a reliable, automatic execution process when investment conditions are met. Furthermore, we redefine the liquidity of existing assets through asset digitization (tokenization) and ensure efficiency and transparency at the same time by implementing a decentralized trading environment without intermediaries through DeFi (decentralized finance).`,
      },
      solution2: {
        solutionTitle: "Artificial Intelligence (AI)",
        solutionSubTitle: "Artificial Intelligence (AI)",
        solutionContent: `AI-based investment solutions learn non-linear patterns in market data through the latest Deep Learning and Reinforcement Learning algorithms and quantify the risks of complex financial products such as Security Token Offerings (STOs). This solution precisely assesses market volatility through Big Data analysis and predictive modeling, and provides personalized portfolio suggestions to help users make more strategic investment decisions.`,
      },
      solution3: {
        solutionTitle: "Automatically",
        solutionSubTitle: "Automate processing",
        solutionContent: `Automated Solutions integrates with Wallet to automate users' transactions and asset management. This enables fast and safe transaction processing using DeFi protocols and smart contracts, saving time and maximizing reliability in advanced investment models such as STO. Additionally, we provide an optimized asset management system that simplifies complex financial transactions and maximizes users’ returns. This automation demonstrates the true stability and efficiency of decentralized financial systems.`,
      },
    },
    // 서비스상세
    serviceDetail: {
      servicePageSubTitle: "Customized and optimized service",
      service1: {
        serviceTitle: "Hanjokak",
        serviceSubTitle:
          "Medical Devices · Hypercars · Fine Art · Real Estate · Bonds",
        serviceContent: `OneSlice STO platform is a platform service that provides safety, transparency, and accessibility to investors, enabling them to directly trade and invest in real-world assets that go beyond the limits of traditional financial markets, and generate additional user benefits through such transactions. It is constantly evolving through various asset classes and global expansion, creating a new financial ecosystem that is easily accessible to everyone.`,
      },
      service2: {
        serviceTitle: "Prime Equity Exchange",
        serviceSubTitle: "Prime Equity Exchange",
        serviceContent: `It provides integrated asset smart solutions such as real estate transaction, asset formation management, investment type analysis, and asset management calculation based on real estate assets. Map-linked analysis As an investment platform, it provides services that can analyze commercial areas and environments numerically and visually by modeling and linking AI map information that can be analyzed and predicted.`,
      },
      service3: {
        serviceTitle: "Digital Wallet ·  DAPP",
        serviceSubTitle: "E-Wallets & Decentralized Applications",
        serviceContent: `The Wallet is based on DLT (distributed ledger technology) to fully secure users' digital assets and provides a decentralized asset management environment through private keys. Seamlessly integrating with smart contracts and DeFi platforms, the Wallet and autonomous applications (DAPPs) work in conjunction with ReturnPlus' proprietary security system to minimize threats such as hacking and data loss, ensuring that users' assets are fully protected.`,
      },
    },
  },
};

const koServiceResource = {
  serviceTranslation: {
    bannerExplain: "지금것 경험하지 못한 새로운 서비스가 곧 찾아옵니다.",
    mbannerExplain: "지금것 경험하지 못한\n새로운 서비스가 곧 찾아옵니다.",
    content: {
      contentExplan:
        "앞선 기술과 전문성으로 유연하고 맞춤형 솔루션과 서비스를 제공합니다.",
      mcontentExplan:
        "앞선 기술과 전문성으로\n유연하고 맞춤형 솔루션과 서비스를 제공합니다.",
      solutionDetailTitle: "상세솔루션",
      serviceDetailTitle: "상세서비스",
    },
    solutionDetail: {
      solutionPageSubTitle: "사용자 맞춤형으로 최적화된 솔루션",
      solution1: {
        solutionTitle: "Distributed Ledger Technology",
        solutionSubTitle: "분산원장기술",
        solutionContent: `DLT(Distributed Ledger Technology)에 기반하여 거래와 데이터를 암호화된 방식으로 분산 저장함으로써 보안을 극대화합니다. 스마트 계약(Smart Contract)은 프로그래머블 금융(Financial Programming)을 가능하게 하며, 투자 조건이 충족되면 신뢰할 수 있는 자동 실행 프로세스를 제공합니다. 더 나아가, 자산의 디지털화(Tokenization)를 통해 기존 자산의 유동성을 재정의하고, DeFi(탈중앙화 금융)를 통해 중개자 없는 탈중앙화된 거래 환경을 구현하여 효율성과 투명성을 동시에 보장합니다.`,
      },
      solution2: {
        solutionTitle: "Artificial Intelligence(AI)",
        solutionSubTitle: "인공지능",
        solutionContent: `AI 기반 투자 솔루션은 최신 딥러닝(Deep Learning)과 강화학습(Reinforcement Learning) 알고리즘을 통해 시장 데이터의 비선형 패턴을 학습하며, STO(Security Token Offering)와 같은 복잡한 금융 상품의 위험을 정량화합니다. 이 솔루션은 빅데이터(Big Data) 분석 및 예측 모델링을 통해 시장 변동성을 정밀하게 평가하고, 사용자가 더 전략적인 투자 결정을 내릴 수 있도록 개인화된 포트폴리오 제안을 제공합니다.`,
      },
      solution3: {
        solutionTitle: "Automatically",
        solutionSubTitle: "처리자동화",
        solutionContent: `Automated Solutions은 Wallet과 연동하여 사용자의 거래와 자산 관리를 자동화합니다. 이를 통해 DeFi 프로토콜 및 스마트 계약을 활용한 빠르고 안전한 거래 처리가 가능하며, STO와 같은 고도화된 투자 모델에서 시간을 절약하고 신뢰성을 극대화합니다. 또한, 복잡한 금융 거래를 간소화하고 사용자의 수익률을 극대화하는 최적화된 자산 운용 시스템을 제공합니다. 이러한 자동화는 분산형 금융 시스템의 진정한 안정성과 효율성을 입증합니다.`,
      },
    },
    // 서비스상세
    serviceDetail: {
      servicePageSubTitle: "사용자 맞춤형으로 최적화된 서비스",
      service1: {
        serviceTitle: "한조각",
        serviceSubTitle: "의료기기 · 하이퍼카 · 미술품 · 부동산 · 채권",
        serviceContent: `한조각 STO 플랫폼은 투자자에게 안전성, 투명성, 접근성을 제공하며, 기존 금융 시장의 한계를 넘어선 실물 자산을 직접거래, 투자할 수 있으며, 해당 거래를 통해 추가적인 사용자 의 이득을 발생시키는 플랫폼 서비스입니다. 다양한 자산군과 글로벌 확장을 통해 지속적으로 발전해 나 가며, 누구나 쉽게 접근할 수 있는 신금융 생태계를 만들어 가고 있습니다.`,
      },
      service2: {
        serviceTitle: "프라임 지분거래소",
        serviceSubTitle: "Prime Equity Exchange",
        serviceContent: `부동산 자산을 기반으로 부동산거래, 자산 형 성 관리, 투자유형분석, 자산관리 계산등 통합 자산 스마트 솔루션을 제공하며 지도 연동 분석 투자 플랫폼으로서 분석과 예측이 가능한 AI 지도 정보를 모델화 연동 하여 상권 및 환경을 수치적/시각적으로 분석할 수 있는 서비스를 제공합니다.`,
      },
      service3: {
        serviceTitle: "Digital Wallet ·  DAPP",
        serviceSubTitle: "전자 지갑 및 분산어플리케이션",
        serviceContent: `Wallet은 DLT(분산 원장 기술)를 기반으로 하여 사용자의 디지털 자산을 완벽히 보안처리하며, 프라이빗 키를 통해 탈중앙화된 자산 관리 환경을 제공합니다. 스마트 계약 및 DeFi 플랫폼과 원활하게 통합되는 Wallet과 자율적으로 작동되는 어플리케이션은(DAPP) ReturnPlus의 독자적인 보안 시스템과 연동되어 해킹, 데이터 손실 등의 위협을 최소화하며, 사용자의 자산을 철저히 보호합니다.`,
      },
    },
  },
};

export { enServiceResources, koServiceResource };
