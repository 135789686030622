import { useEffect, useRef } from "react";
function useInterval(callback, delay, deps = []) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, ...deps]); // 의존성 배열에 deps 추가
}
export default useInterval;
