export const companySvgList = [
  {
    name: "Transparent",
    descriptions:
      "CompanyContents.CoreValues.Slogan.SvgList.Transparent.Content",
    icon: () => {
      return (
        <svg
          id="_레이어_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 44.52 44.52"
          width="current"
          height="current"
        >
          <path
            d="M21.86,44.52C9.8,44.52,0,34.71,0,22.66S9.8.81,21.86.81c2.88,0,5.69.56,8.34,1.65l-.81,1.96c-2.39-.99-4.92-1.49-7.53-1.49C10.97,2.92,2.12,11.78,2.12,22.66s8.85,19.74,19.74,19.74,19.74-8.85,19.74-19.74c0-2.66-.52-5.24-1.55-7.67l1.95-.83c1.14,2.69,1.72,5.55,1.72,8.5,0,12.05-9.8,21.86-21.86,21.86Z"
            style={{ fill: "#fff", strokeWidth: 0 }}
          />
          <path
            d="M21.86,38.86c-8.93,0-16.19-7.26-16.19-16.19S12.93,6.47,21.86,6.47c1.91,0,3.78.33,5.56.98l-.73,1.99c-1.54-.57-3.17-.85-4.83-.85-7.76,0-14.08,6.32-14.08,14.08s6.31,14.08,14.08,14.08,14.08-6.31,14.08-14.08c0-1.65-.28-3.26-.84-4.8l1.99-.72c.64,1.77.97,3.63.97,5.52,0,8.93-7.26,16.19-16.19,16.19Z"
            style={{ fill: "#fff", strokeWidth: 0 }}
          />
          <path
            d="M21.86,33.19c-5.81,0-10.53-4.73-10.53-10.53s4.73-10.53,10.53-10.53c1.47,0,2.9.3,4.24.89l-.86,1.94c-1.07-.47-2.21-.71-3.39-.71-4.64,0-8.41,3.78-8.41,8.42s3.77,8.42,8.41,8.42,8.42-3.78,8.42-8.42c0-1.18-.24-2.32-.71-3.39l1.94-.86c.59,1.34.89,2.77.89,4.24,0,5.81-4.73,10.53-10.53,10.53Z"
            style={{ fill: "#fff", strokeWidth: 0 }}
          />
          <path
            d="M21.86,27.53c-2.69,0-4.87-2.19-4.87-4.87s2.19-4.87,4.87-4.87v2.12c-1.52,0-2.75,1.24-2.75,2.76s1.24,2.76,2.75,2.76,2.75-1.24,2.75-2.76h2.12c0,2.69-2.19,4.87-4.87,4.87Z"
            style={{ fill: "#fff", strokeWidth: 0 }}
          />
          <rect
            x="18.73"
            y="14.06"
            transform="translate(-2.08 25.23) rotate(-45.02)"
            style={{ fill: "#fff", strokeWidth: 0 }}
          />
          <path
            d="M30.36,15.65l-1.03-7.97,7.68-7.68,1.03,7.97-7.68,7.68ZM31.57,8.44l.34,2.66,3.9-3.9-.35-2.66-3.9,3.9Z"
            style={{ fill: "#fff", strokeWidth: 0 }}
          />
          <path
            d="M36.83,15.18l-7.97-1.03,7.68-7.68,7.97,1.03-7.68,7.68ZM33.41,12.6l2.66.35,3.9-3.9-2.66-.35-3.9,3.9Z"
            style={{ fill: "#fff", strokeWidth: 0 }}
          />
        </svg>
      );
    },
  },
  {
    name: "Safety",
    descriptions: "CompanyContents.CoreValues.Slogan.SvgList.Safety.Content",
    icon: () => {
      return (
        <svg
          id="_레이어_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 38.33 44.7"
          width="current"
          height="current"
        >
          <path
            d="M19.17,44.7l-.29-.09C7.76,41.39,0,31.03,0,19.43V5.72L19.17,0l19.17,5.72v13.71c0,11.6-7.76,21.96-18.87,25.18l-.29.09ZM2.12,7.3v12.13c0,10.56,6.99,20,17.05,23.06,10.06-3.06,17.05-12.5,17.05-23.06V7.3L19.17,2.22,2.12,7.3Z"
            style={{ fill: "#fff", strokeWidth: 0 }}
          />
          <path
            d="M19.17,33.7c-6.37,0-11.56-5.2-11.56-11.59s5.18-11.59,11.56-11.59,11.56,5.2,11.56,11.59-5.19,11.59-11.56,11.59ZM19.17,12.64c-5.21,0-9.44,4.25-9.44,9.47s4.23,9.47,9.44,9.47,9.44-4.25,9.44-9.47-4.24-9.47-9.44-9.47Z"
            style={{ fill: "#fff", strokeWidth: 0 }}
          />
          <rect x="18.11" y="1.11" style={{ fill: "#fff", strokeWidth: 0 }} />
          <rect x="18.11" y="35.91" style={{ fill: "#fff", strokeWidth: 0 }} />
          <polygon
            points="18.25 26.41 13.75 21.89 15.25 20.39 18.25 23.41 23.08 18.56 24.58 20.06 18.25 26.41"
            style={{ fill: "#fff", strokeWidth: 0 }}
          />
        </svg>
      );
    },
  },
  {
    name: "Community",
    descriptions: "CompanyContents.CoreValues.Slogan.SvgList.Community.Content",
    icon: () => {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 42 66"
          fill="none"
          width="current"
          height="current"
        >
          <path
            d="M21.0667 42.5C9.58265 42.5 0.195679 33.1 0.195679 21.5C0.195679 9.9 9.58265 0.5 21.0667 0.5C32.5507 0.5 41.9377 9.9 41.9377 21.5C41.9377 33.1 32.6506 42.5 21.0667 42.5ZM21.0667 4.1001C11.48 4.1001 3.69082 11.9 3.69082 21.5C3.69082 31.1 11.48 38.8999 21.0667 38.8999C30.6534 38.8999 38.4426 31.1 38.4426 21.5C38.4426 11.9 30.6534 4.1001 21.0667 4.1001Z"
            fill="white"
          />
          <path
            d="M32.6506 44.1001H9.58264V47.6001H32.6506V44.1001Z"
            fill="white"
          />
          <path d="M32.6506 55.3H9.58264V58.8H32.6506V55.3Z" fill="white" />
          <path d="M32.6506 49.7H9.58264V53.2H32.6506V49.7Z" fill="white" />
          <path
            d="M21.0667 65.6001C18.67 65.6001 16.2733 64.6999 14.4758 62.8999L16.9724 60.3999C19.2692 62.6999 22.964 62.6999 25.2609 60.3999L27.7574 62.8999C25.86 64.5999 23.4634 65.6001 21.0667 65.6001Z"
            fill="white"
          />
          <path
            d="M19.5688 28.8L12.0792 21.3L14.5758 18.8L19.5688 23.8L27.6576 15.7L30.1541 18.2L19.5688 28.8Z"
            fill="white"
          />
        </svg>
      );
    },
  },
];
