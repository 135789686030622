// NewsDetail.js
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PrintSvg } from "../../assets/images/뉴스_프린트.svg";
import { ReactComponent as ShareSvg } from "../../assets/images/뉴스_공유.svg";
import { useEffect } from "react";
import { fetchNews } from "../../store/newsSlice";

function NewsDetail() {
  const { newsId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { allList, status } = useSelector((state) => state.news);

  const newsItem = allList.find((item) => item._id === newsId);

  const handlePrint = () => {
    window.print();
  };

  // === [공유하기 버튼] 클릭 시 동작 ===
  const handleShare = () => {
    // 1. Web Share API 지원 여부 확인
    if (navigator.share) {
      // 2. Web Share API를 지원하는 환경에서는 OS 공유 UI 사용
      navigator
        .share({
          title: newsItem.title,
          text: newsItem.summary,
          url: window.location.href,
        })
        .then(() => {
          console.log("공유 성공");
        })
        .catch((err) => {
          console.error("공유 오류:", err);
        });
    } else {
      // 3. Web Share API 미지원 환경에서는 기존 복사 로직 fallback
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard
          .writeText(window.location.href)
          .then(() => {
            alert("현재 페이지의 URL이 클립보드에 복사되었습니다!");
          })
          .catch((err) => {
            console.error("URL 복사 실패:", err);
            alert("URL 복사에 실패했습니다.");
          });
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = window.location.href;
        textArea.style.position = "fixed";
        textArea.style.top = "-1000px";
        textArea.style.left = "-1000px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          const successful = document.execCommand("copy");
          if (successful) {
            alert("현재 페이지의 URL이 클립보드에 복사되었습니다!");
          } else {
            alert("URL 복사에 실패했습니다.");
          }
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
          alert("URL 복사에 실패했습니다.");
        }
        document.body.removeChild(textArea);
      }
    }
  };

  // 뉴스 데이터 fetch
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchNews());
    }
  }, [status, dispatch]);

  // 404 대응
  if (!newsItem) {
    return (
      <div className="wrapper">
        <Link to="/news" className="back_link">
          ← 뉴스 목록으로 돌아가기
        </Link>
        <h2>해당 뉴스를 찾을 수 없습니다.</h2>
      </div>
    );
  }

  return (
    <div className="wrapper">
      <div className="title_box">
        <Link to="/news" className="back_link">
          <span>News</span>
          <span>
            <svg
              className="arrow_svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8.31 4.8"
              width="current"
              height="current"
            >
              <path
                d="M3.49,4.5c.35.35.86.4,1.14.12s.22-.79-.12-1.14L1.31.3C.97-.05.46-.1.18.18-.1.46-.05.97.3,1.31l3.19,3.19Z"
                fill="#ffffff"
                strokeWidth="1px"
              />
              <path
                d="M8,1.31c.35-.35.4-.86.12-1.14s-.79-.22-1.14.12l-3.19,3.19c-.35.35-.4.86-.12,1.14.28.28.79.22,1.14-.12l3.19-3.19Z"
                fill="#ffffff"
                strokeWidth="0px"
              />
            </svg>
          </span>
          <span>{t("newsTranslation.newBtn")}</span>
        </Link>
        <h1 className="news_title">{t(`news_${newsItem._id}_title`)}</h1>
        <div className="date_tool_box">
          <span className="news_date">
            {t("newsTranslation.creationDate")}
            {newsItem.date.split(" ")[0].replace(/-/g, ".")}
          </span>
          <div className="tool_box">
            <button
              className="tool_btn"
              onClick={handlePrint}
              aria-label="Print this page"
            >
              <PrintSvg className="tool_icon" />
            </button>
            <button
              className="tool_btn"
              onClick={handleShare}
              aria-label="Share this page"
            >
              <ShareSvg className="tool_icon" />
            </button>
          </div>
        </div>
      </div>

      <div className="content_box">
        <span className="summation">{newsItem.summary}</span>

        {newsItem.image && (
          <img
            src={newsItem.image}
            alt={newsItem.title}
            loading="lazy"
            decoding="async"
            className="news_image"
          />
        )}

        <span className="press">{t(`news_${newsId}_press`)}</span>

        <div
          className="news_content"
          dangerouslySetInnerHTML={{ __html: t(`news_${newsItem._id}_content`) }}
        />

        <a
          href={newsItem.url}
          className="go_to_news"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("newsTranslation.goToNewsBtn")}
        </a>
        <Link to="/company/#irdown" className="inquiry">
          {t("newsTranslation.inquiryIR")}
        </Link>
      </div>
    </div>
  );
}

export default NewsDetail;
